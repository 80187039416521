import React, { forwardRef, useState, useEffect } from "react";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { MiscUtil } from "../utils/miscUtil";
import { pidl } from "@cspayments/pidl-react";
import {
  PidlConfigParams,
  LeftNavigation,
  EventName,
} from "../schema/taxProfileMetaData.types";
import { Dropdown, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { PayfromCountryDetailsType } from "../schema/taxProfileMetaData.types";
import { PidlGenerator, PidlGeneratorRefType } from "./pidlGenerator";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Text } from "@fluentui/react";
import { CookieUtil } from "../utils/cookieUtil";
import * as Constants from "../constant/constants";
import { useTranslation } from "react-i18next";

interface OtherDocumentationRefType {
  goNext(): void;
  goBack(): void;
}

type OtherDocumentationPropsType = {
  prefillData: any;
  callbackOnSuccess: (data?: any) => void;
  callbackOnFailure: (data?: any) => void;
  onPidlEvent: (eventName: string, parameters: any) => void;
  pidlConfigParams: PidlConfigParams;
  leftNavigation: LeftNavigation;
};

// Styling definitions
const parentStackTokens: IStackTokens = {
  childrenGap: 10,
  maxWidth: 500,
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
  root: { height: 100 },
};
const stackTokens: IStackTokens = { childrenGap: 10 };
const saveStack: IStackTokens = { maxWidth: 80, childrenGap: 10 };
const pidlStack: IStackTokens = { padding: "30px 0px 0px 0px" };

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
});
const classNames = mergeStyleSets({
  redIcon: [{ color: "red" }, iconClass],
  optionWithError: {
    width: "300px",
    display: "flex",
    justifyContent: "space-between",
    color: "red",
  },
});

/**
 * helper function to construct an array of object that can be used for rendering dropdown options
 * The dropdown contains all the countries in the payFromCountryDetails.
 * @param payFromCountryDetails - An array of objects that can map country code to country name
 * @returns a list of pay from countries that can be used for rendering dropdown options
 */
const countryOptionsConstructor = (
  payFromCountryDetails: PayfromCountryDetailsType[]
): IDropdownOption[] => {
  if (payFromCountryDetails.length == 0) {
    return [];
  }

  let res: IDropdownOption[] = [];
  payFromCountryDetails.forEach((node, i) => {
    res.push({
      key: node.Key,
      text: node.Value,
    });
  });
  return res;
};

const parseIncometypecodes = (
  countryIncomeCodes: any,
  country: string
): string => {
  for (const countryName in countryIncomeCodes) {
    if (countryName === country) {
      return countryIncomeCodes[countryName].join(",");
    }
  }
  return "";
};

const onRenderOption = (option?: IDropdownOption): JSX.Element => {
  const optionText = option!.text;

  if (optionText.at(-1) === "*") {
    return (
      <div className={classNames.optionWithError}>
        <span>{optionText}</span>
      </div>
    );
  } else {
    return (
      <div>
        <span>{optionText}</span>
      </div>
    );
  }
};

export const OtherDocumentation = forwardRef<
  OtherDocumentationRefType,
  OtherDocumentationPropsType
>((props, parentRef) => {
  let saveButtonRef: PidlGeneratorRefType;

  const { t } = useTranslation();
  const { prefillData, pidlConfigParams } = props;
  const [payFromCountryList, setPayFromCountryList] = useState<
    IDropdownOption[]
  >(countryOptionsConstructor(pidlConfigParams.payfrom_country_details));
  const [selectedCountry, setSelectedCountry] = useState<string>(
    pidlConfigParams.otherdocumentation_selectedCountryCode
  );
  // state controls the uploading spinner
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [pidlLoaded, setPidlLoaded] = useState<boolean>(false);

  const handlePayFromCountry = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ): void => {
    setSelectedCountry(option?.key.toString() || "");
  };

  // custom save button event handler that binds to the submit pidlAction in pidl.json
  const handleUpload = () => {
    saveButtonRef?.goNext();
  };

  // set the default country to the first one in the dropdown
  // if otherdocumentation_selectedCountryCode does not exist.
  useEffect(() => {
    if (
      !MiscUtil.isNullOrUndefinedOrEmptyArray(payFromCountryList) &&
      MiscUtil.isNullOrUndefinedOrEmptyString(selectedCountry)
    ) {
      setSelectedCountry(payFromCountryList.at(0)?.key.toString() || "");
    }
  }, [payFromCountryList]);

  // callback function on PIDL rendering success
  const callbackOnSuccess = (data: any) => {
    setIsUploading(false);
    props.callbackOnSuccess({
      ...data,
      isNext: false,
    });
  };

  // callback function on PIDL rendering failure
  const callbackOnFailure = (data: any) => {
    setIsUploading(false);
    props.callbackOnFailure(data);
  };

  // Handler for addressing pre-defined events from PIDL SDK
  const onPidlEvent: pidl.PidlEventHandler = (
    eventName: string,
    parameters: any
  ): void => {
    if (eventName === "pageRendered") {
      setPidlLoaded(true);
    }
    // disable save button & dropdown while file uploading
    if (eventName === EventName.FileUploading) {
      setIsUploading(true);
    }
    props.onPidlEvent(eventName, parameters);
  };

  return (
    <Stack tokens={parentStackTokens}>
      <Stack tokens={stackTokens} verticalAlign="start">
        {payFromCountryList.length === 0 ? (
          <div>No Documentation Required</div>
        ) : (
          <>
            {!MiscUtil.isNullOrUndefinedOrEmptyString(selectedCountry) && (
              <>
                <Dropdown
                  label={props.leftNavigation.country_dropdown_title!}
                  placeholder={
                    props.leftNavigation.country_dropdown_placeholder!
                  }
                  onRenderOption={onRenderOption}
                  options={payFromCountryList}
                  onChange={handlePayFromCountry}
                  styles={dropdownStyles}
                  defaultSelectedKey={selectedCountry}
                  disabled={isUploading}
                />
                <Text>
                  {props.pidlConfigParams.uploadDocument_help_message1}
                </Text>
                <Text>
                  {props.pidlConfigParams.uploadDocument_help_message2}
                </Text>
                <Text>
                  {props.pidlConfigParams.uploadDocument_help_message3}
                </Text>
                <Stack tokens={pidlStack}>
                  {!pidlLoaded && <Spinner label={t("loading") + "..."} />}
                  <PidlGenerator
                    ref={(pidlGeneratorRef) => {
                      if (pidlGeneratorRef) {
                        saveButtonRef = pidlGeneratorRef;
                      }
                    }}
                    callbackOnSuccess={callbackOnSuccess}
                    callbackOnFailure={callbackOnFailure}
                    onPidlEvent={onPidlEvent}
                    pidlConfigParams={{
                      ...props.pidlConfigParams,
                      pay_from_countries: [selectedCountry],
                      otherdocumentation_selectedCountryCode: selectedCountry,
                    }}
                    additionalHeaders={{
                      showcti:
                        props.pidlConfigParams.payee_additional_info.DisplayCTI,
                      incometypecodes: parseIncometypecodes(
                        props.pidlConfigParams.country_income_codes,
                        selectedCountry
                      ),
                      authToken:
                        CookieUtil.getCookie(Constants.sessionIdCookieName)! +
                        "|" +
                        CookieUtil.getCookie(Constants.userKeyCookieName)!,
                    }}
                  />
                </Stack>
                <Stack horizontal tokens={saveStack}>
                  <PrimaryButton
                    text={props.leftNavigation.save_button_text!}
                    onClick={handleUpload}
                    disabled={isUploading}
                  />
                  {isUploading && (
                    <Spinner
                      labelPosition="right"
                      label={props.leftNavigation.save_spinner_text!}
                    />
                  )}
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
});
