import i18n, { Module } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// TODO: The language resources will have to be added as more langauges become available. Potentially
//       multiple locale files would eventually replace these as it's more scalable/manageable.
const resources = {
  af: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Iets het verkeerd gegaan",
      default_error_message:
        "Ons het ’n kwessie vir verdere ondersoek aangeteken, teken asseblief uit en probeer weer.",
    },
  },
  am: {
    translation: {
      loading: "በመጫን ላይ",
      authorizing: "ፈቃድ መስጠት",
      error_title: "የሆነ ነገር ተሳስቷል",
      default_error_message:
        "ለተጨማሪ ምርመራ አንድ ጉዳይ አስገብተናል፣ እባክዎ በመለያ ይውጡ እና እንደገና ይሞክሩ።",
    },
  },
  ar: {
    translation: {
      loading: "يتم الآن التحميل",
      authorizing: "يتم الآن التخويل",
      error_title: "حدث خطأ ما",
      default_error_message:
        "لقد قمنا بتسجيل مشكلة لإجراء فحص إضافي، الرجاء تسجيل الخروج والمحاولة مجدداً.",
    },
  },
  as: {
    translation: {
      loading: "ল’ড হৈ আছে",
      authorizing: "কৰ্তৃত্ব প্ৰদান কৰা হৈছে",
      error_title: "কিবা সমস্যা হ’ল",
      default_error_message:
        "আমি পৰৱৰ্তী অনুসন্ধানৰ বাবে এটা সমস্যা লগ কৰিছোঁ, অনুগ্ৰহ কৰি লগ আউট কৰক আৰু পুনৰ চেষ্টা কৰক।",
    },
  },
  az: {
    translation: {
      loading: "yüklənir",
      authorizing: "icazə vermək",
      error_title: "Xəta baş verdi",
      default_error_message:
        "Əlavə araşdırma üçün problemi daxil etdik, çıxın və yenidən cəhd edin.",
    },
  },
  be: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  bg: {
    translation: {
      loading: "зареждане",
      authorizing: "разрешаване",
      error_title: "Възникна грешка",
      default_error_message:
        "Регистрирахме проблем за по-нататъшно проучване – излезте и опитайте отново.",
    },
  },
  "bn-bd": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "bn-in": {
    translation: {
      loading: "লোড হচ্ছে",
      authorizing: "অনুমোদন করা হচ্ছে",
      error_title: "কিছু ত্রুটি ঘটেছে",
      default_error_message:
        "আমরা আরও তদন্তের জন্য একটি সমস্যার লগ করেছি, অনুগ্রহ করে লগ আউট করুন এবং আবার চেষ্টা করুন।",
    },
  },
  bs: {
    translation: {
      loading: "učitavanje",
      authorizing: "autoriziranje",
      error_title: "Pojavila se greška",
      default_error_message:
        "Prijavili smo problem za dodatnu istragu, odjavite se i pokušajte ponovo.",
    },
  },
  ca: {
    translation: {
      loading: "s'està carregant",
      authorizing: "authorizing",
      error_title: "Hi ha hagut un error",
      default_error_message:
        "Hem registrat un problema per investigar-lo. Tanqueu la sessió i torneu-ho a provar.",
    },
  },
  "ca-es-valencia": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "Hem registrat un problema per investigar-lo. Tanqueu la sessió i torneu-ho a provar.",
    },
  },
  cs: {
    translation: {
      loading: "probíhá načítání",
      authorizing: "probíhá autorizace",
      error_title: "Něco se nepovedlo",
      default_error_message:
        "Zaprotokolovali jsme problém pro další šetření. Odhlaste se prosím a zkuste to znovu.",
    },
  },
  cy: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Aeth rhywbeth o'i le",
      default_error_message:
        "Rydym wedi logio problem ar gyfer ymchwiliad pellach, allgofnodwch a rhoi cynnig arall arni.",
    },
  },
  da: {
    translation: {
      loading: "indlæser",
      authorizing: "Giver tilladelse",
      error_title: "Noget gik galt",
      default_error_message:
        "Vi har registreret et problem til yderligere undersøgelse. Log af, og prøv igen.",
    },
  },
  de: {
    translation: {
      loading: "wird geladen",
      authorizing: "Autorisierung wird durchgeführt",
      error_title: "Da hat etwas nicht geklappt",
      default_error_message:
        "Wir haben ein Problem zur weiteren Untersuchung protokolliert, bitte melden Sie sich ab, und versuchen Sie es noch mal.",
    },
  },
  el: {
    translation: {
      loading: "φόρτωση",
      authorizing: "εξουσιοδότηση",
      error_title: "Παρουσιάστηκε κάποιο πρόβλημα",
      default_error_message:
        "Έχουμε καταγράψει ένα πρόβλημα για περαιτέρω διερεύνηση. Αποσυνδεθείτε και δοκιμάστε ξανά.",
    },
  },
  en: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "en-gb": {
    translation: {
      loading: "loading",
      authorizing: "authorising",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "en-us": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  es: {
    translation: {
      loading: "cargando",
      authorizing: "autorizando",
      error_title: "Se ha producido un error",
      default_error_message:
        "Hemos registrado un problema para una investigación más detallada. Cierre la sesión e inténtelo de nuevo.",
    },
  },
  "es-mx": {
    translation: {
      loading: "cargando",
      authorizing: "autorizando",
      error_title: "Se ha producido un error",
      default_error_message:
        "Registramos un problema para una investigación más detallada. Cierra la sesión e inténtalo de nuevo.",
    },
  },
  et: {
    translation: {
      loading: "laadimine",
      authorizing: "volitamine",
      error_title: "Midagi läks valesti",
      default_error_message:
        "Logisime probleemi edasiseks uurimiseks, logige välja ja proovige uuesti.",
    },
  },
  eu: {
    translation: {
      loading: "kargatzen",
      authorizing: "authorizing",
      error_title: "Arazoren bat izan da",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  fa: {
    translation: {
      loading: "در حال بارگیری",
      authorizing: "اجازه‌دهی",
      error_title: "مشکلی رخ داد",
      default_error_message:
        "ما مشکلی را برای بررسی بیشتر ثبت کرده‌ایم، لطفاً از سیستم خارج شوید و دوباره امتحان کنید.",
    },
  },
  fi: {
    translation: {
      loading: "ladataan",
      authorizing: "valtuutetaan",
      error_title: "Havaittiin virhe",
      default_error_message:
        "Ongelma on kirjattu lisätutkimuksia varten. Kirjaudu ulos ja yritä uudelleen.",
    },
  },
  fil: {
    translation: {
      loading: "naglo-load",
      authorizing: "pinapahintulutan",
      error_title: "Nagkaroon ng problema",
      default_error_message:
        "Nag-log kami ng isyu para sa karagdagang imbestigasyon, mangyaring mag-log out at subukang muli.",
    },
  },
  fr: {
    translation: {
      loading: "chargement",
      authorizing: "authorizing",
      error_title: "Nous avons rencontré un problème",
      default_error_message:
        "Nous avons consigné un problème à des fins d’enquête plus approfondie. Déconnectez-vous, puis réessayez.",
    },
  },
  "fr-ca": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "Nous avons consigné un problème à des fins d’enquête plus approfondie. Déconnectez-vous, puis réessayez.",
    },
  },
  ga: {
    translation: {
      loading: "ag lódáil...",
      authorizing: "ag údarú",
      error_title: "Bhí fadhb ann",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  gd: {
    translation: {
      loading: "ga luchdadh",
      authorizing: "ga dhearbhadh",
      error_title: "Chaidh rudeigin ceàrr",
      default_error_message:
        "Chruthaich sinn tiocaid airson barrachd rannsachaidh, clàraich a-mach is feuch ris a-rithist.",
    },
  },
  gl: {
    translation: {
      loading: "cargando",
      authorizing: "autorizando",
      error_title: "Produciuse un erro",
      default_error_message:
        "Rexistramos un problema para levar a cabo unha investigación máis exhaustiva; peche sesión e ténteo de novo.",
    },
  },
  gu: {
    translation: {
      loading: "લોડ થઈ રહ્યું છે",
      authorizing: "અધિકૃત કરી રહ્યું છે",
      error_title: "કંઇક ખોટું થયું",
      default_error_message:
        "અમે વધુ તપાસ માટે સમસ્યા લૉગ કરી છે, કૃપયા લૉગ આઉટ કરો અને ફરી પ્રયાસ કરો.",
    },
  },
  ha: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  he: {
    translation: {
      loading: "טוען",
      authorizing: "מאשר",
      error_title: "משהו השתבש",
      default_error_message: "תיעדנו בעיה לצורך המשך חקירה. התנתק ונסה שוב.",
    },
  },
  hi: {
    translation: {
      loading: "लोड हो रहा है",
      authorizing: "प्राधिकृत",
      error_title: "कुछ गड़बड़ी हुई",
      default_error_message:
        "हमने आगे की जाँच के लिए एक समस्या को लॉग किया है, कृपया लॉग आउट करें और फिर से कोशिश करें.",
    },
  },
  hr: {
    translation: {
      loading: "učitavanje",
      authorizing: "Ovlašćivanje",
      error_title: "Došlo je do pogreške",
      default_error_message:
        "Prijavili smo problem radi daljnje istrage, odjavite se pa pokušajte ponovno.",
    },
  },
  hu: {
    translation: {
      loading: "betöltés folyamatban",
      authorizing: "engedélyezés folyamatban",
      error_title: "Hiba történt",
      default_error_message:
        "További vizsgálat céljából naplóztunk egy problémát. Kérjük, jelentkezzen ki, és próbálkozzon újra.",
    },
  },
  hy: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Ինչ-որ սխալ տեղի ունեցավ",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  id: {
    translation: {
      loading: "memuat",
      authorizing: "mengotorisasi",
      error_title: "Ada yang salah",
      default_error_message:
        "Kami telah melaporkan masalahnya untuk diselidiki lebih lanjut, silakan keluar dan coba lagi.",
    },
  },
  ig: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  is: {
    translation: {
      loading: "hleðsla",
      authorizing: "heimilar",
      error_title: "Eitthvað fór úrskeiðis",
      default_error_message:
        "Við höfum skráð vandamál til frekari rannsóknar, vinsamlegast skráðu þig út og reyndu aftur.",
    },
  },
  it: {
    translation: {
      loading: "caricamento",
      authorizing: "authorizing",
      error_title: "Si è verificato un errore",
      default_error_message:
        "È stato registrato un problema che necessita di ulteriori indagini. Disconnettersi e riprovare.",
    },
  },
  ja: {
    translation: {
      loading: "ロード中",
      authorizing: "認証中",
      error_title: "問題が発生しました",
      default_error_message:
        "さらに調査するために問題がログに記録されました。ログアウトして、もう一度お試しください。",
    },
  },
  ka: {
    translation: {
      loading: "იტვირთება",
      authorizing: "მიმდინარეობს ავტორიზაცია",
      error_title: "მოხდა შეცდომა",
      default_error_message:
        "ჩვენ გადავეცით პრობლემა შემდგომი შესწავლისთვის, გამოდით სისტემიდან და ხელახლა სცადეთ.",
    },
  },
  kk: {
    translation: {
      loading: "жүктелуде",
      authorizing: "авторизациялау",
      error_title: "Бірдеңе дұрыс болмады",
      default_error_message:
        "Біз қосымша зерттеу жұмысын жүргізу үшін мәселені тіркедік. Жүйеден шығып, әрекетті қайталаңыз.",
    },
  },
  km: {
    translation: {
      loading: "កំពុងទាញ",
      authorizing: "ការអនុញ្ញាត",
      error_title: "មានអ្វីមួយបានខុសឆ្គង",
      default_error_message:
        "យើងបានធ្វើកំណត់ហេតុអំពីបញ្ហាសម្រាប់ការស៊ើបអង្កេតបន្ថែម សូមចុះឈ្មោះចេញ រួចព្យាយាមម្ដងទៀត។",
    },
  },
  kn: {
    translation: {
      loading: "ಲೋಡ್ ಆಗುತ್ತಿದೆ",
      authorizing: "ಪ್ರಮಾಣೀಕರಿಸಿರುವುದು",
      error_title: "ಏನೋ ತಪ್ಪಾಗಿದೆ",
      default_error_message:
        "ಹೆಚ್ಚಿನ ತನಿಖೆಗಾಗಿ ನಾವು ಒಂದು ಸಮಸ್ಯೆಯನ್ನು ಲಾಗ್ ಮಾಡಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ಲಾಗ್ ಔಟ್ ಮಾಡಿ ಹಾಗೂ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    },
  },
  ko: {
    translation: {
      loading: "로드 중",
      authorizing: "권한 부여 중",
      error_title: "오류가 발생함",
      default_error_message:
        "추가 조사를 위해 문제를 기록했습니다. 로그아웃한 후 다시 시도하세요.",
    },
  },
  kok: {
    translation: {
      loading: "लोडींग",
      authorizing: "अधिकृत करप",
      error_title: "किदें तरी चुकीचें घडलें",
      default_error_message:
        "आमी फुडल्या तपासणे खातीर एक मुद्दो लॉग केला, उपकार करून लॉग आवट करचें आनी परतून यत्न करचो.",
    },
  },
  "ku-arab": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  ky: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  lb: {
    translation: {
      loading: "gëtt gelueden",
      authorizing: "gëtt authoriséiert",
      error_title: "Et ass e Problem opgetrueden",
      default_error_message:
        "Mir hunn e Problem fir weider Enquête protokolléiert, mellt Iech wgl. of a probéiert nach eng Kéier.",
    },
  },
  lo: {
    translation: {
      loading: "ກຳ​ລັງ​ໂຫຼດ",
      authorizing: "ການອະນຸຍາດ",
      error_title: "ມີບາງຢ່າງຜິດພາດເກີດຂຶ້ນ",
      default_error_message:
        "ພວກເຮົາໄດ້ບັນທຶກບັນຫາສຳລັບການສືບສວນເພີ່ມເຕີມ, ກະລຸນາອອກລະບົບ ແລະ ລອງໃໝ່ອີກຄັ້ງ.",
    },
  },
  lt: {
    translation: {
      loading: "įkeliama",
      authorizing: "įgaliojantis",
      error_title: "Kažkas nepavyko",
      default_error_message:
        "Užregistravome problemą tolesniam tyrimui. Atsijunkite ir bandykite dar kartą.",
    },
  },
  lv: {
    translation: {
      loading: "notiek ielāde",
      authorizing: "Notiek autorizācija",
      error_title: "Radās kāda problēma",
      default_error_message:
        "Mēs reģistrējām problēmu tālākai izmeklēšanai. Lūdzu, izrakstieties un mēģiniet vēlreiz.",
    },
  },
  mi: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Kua puta mai he hapa",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  mk: {
    translation: {
      loading: "се вчитува",
      authorizing: "овластување",
      error_title: "Нешто не е во ред",
      default_error_message:
        "Најавивме проблем за понатамошна истрага, одјавете се и обидете се повторно.",
    },
  },
  ml: {
    translation: {
      loading: "ലോഡുചെയ്യുന്നു",
      authorizing: "അധികാരപ്പെടുത്തുന്നു",
      error_title: "എന്തോ കുഴപ്പം സംഭവിച്ചു",
      default_error_message:
        "കൂടുതൽ അന്വേഷണത്തിനായി ഞങ്ങൾ ഒരു പ്രശ്നം ലോഗ് ചെയ്തിട്ടുണ്ട്, ദയവായി ലോഗ് ഔട്ട് ചെയ്ത് വീണ്ടും ശ്രമിക്കുക.",
    },
  },
  mn: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  mr: {
    translation: {
      loading: "लोड होत आहे",
      authorizing: "अधिकृत करणे",
      error_title: "काहीतरी चुकले आहे",
      default_error_message:
        "आम्ही पुढील तपासासाठी समस्या लॉग केली आहे, कृपया लॉग आउट करा आणि पुन्हा प्रयत्न करा.",
    },
  },
  ms: {
    translation: {
      loading: "memuatkan",
      authorizing: "memberikan kebenaran",
      error_title: "Sesuatu yang tidak kena telah berlaku",
      default_error_message:
        "Kami telah mengelog isu untuk siasatan lanjut, sila log keluar dan cuba lagi.",
    },
  },
  mt: {
    translation: {
      loading: "qed jillowdja",
      authorizing: "qed jawtorizza",
      error_title: "Ġrat xi ħaġa ħażina",
      default_error_message:
        "Irreġistrajna problema għal aktar investigazzjoni, oħroġ u erġa’ pprova.",
    },
  },
  ne: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "केही चिज गडबड भयो",
      default_error_message:
        "हामीले थप अनुसन्धानका लागि समस्या लग गरेका छौँ, कृपया लग आउट गरी फेरि प्रयास गर्नुहोस्।",
    },
  },
  nl: {
    translation: {
      loading: "laden",
      authorizing: "autoriseren",
      error_title: "Er is iets fout gegaan",
      default_error_message:
        "Er is een probleem geregistreerd voor verder onderzoek. Meld u af en probeer het opnieuw.",
    },
  },
  "nn-no": {
    translation: {
      loading: "lastar inn",
      authorizing: "gir løyve",
      error_title: "Noko gjekk gale",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  no: {
    translation: {
      loading: "laster inn",
      authorizing: "autoriserer",
      error_title: "Noe gikk galt",
      default_error_message:
        "Vi har logget et problem for videre undersøkelse. Logg av og prøv på nytt.",
    },
  },
  nso: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  or: {
    translation: {
      loading: "ଲୋଡ୍ ହେଉଛି",
      authorizing: "ଗ୍ରନ୍ଥକର୍ତ୍ତା",
      error_title: "କିଛି ଭୁଲ୍ ହୋଇଛି",
      default_error_message:
        "ଅଧିକ ଅନୁସନ୍ଧାନ ପାଇଁ ଆମେ ଏକ ସମସ୍ୟା ଲଗ୍ ଆଉଟ୍ କରିଛୁ, ଦୟାକରି ଲଗ୍ ଆଉଟ୍ କରନ୍ତୁ ଏବଂ ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ ।",
    },
  },
  "pa-arab-pk": {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "pa-in": {
    translation: {
      loading: "ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ",
      authorizing: "ਅਧਿਕਾਰਤ",
      error_title: "ਕੁੱਝ ਗਲਤ ਵਾਪਰਿਆ",
      default_error_message:
        "ਅਸੀਂ ਅੱਗੇ ਦੀ ਜਾਂਚ ਲਈ ਇੱਕ ਸਮੱਸਿਆ ਲੌਗ ਕੀਤੀ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਲੌਗ ਆਉਟ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
    },
  },
  pl: {
    translation: {
      loading: "ładowanie",
      authorizing: "autoryzowanie",
      error_title: "Wystąpił błąd",
      default_error_message:
        "Zarejestrowaliśmy problem w celu dalszego badania. Wyloguj się i spróbuj ponownie.",
    },
  },
  prs: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "pt-br": {
    translation: {
      loading: "carregando",
      authorizing: "autorizando",
      error_title: "Algo deu errado",
      default_error_message:
        "Registramos um problema para uma investigação mais aprofundada, saia e tente novamente.",
    },
  },
  "pt-pt": {
    translation: {
      loading: "a carregar",
      authorizing: "a autorizar",
      error_title: "Algo correu mal",
      default_error_message:
        "Registámos um problema para investigação adicional. Termine sessão e tente novamente.",
    },
  },
  quc: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  quz: {
    translation: {
      loading: "qipichkan",
      authorizing: "kamachichkan",
      error_title: "Imapas mana allintachu llamkaykurqa",
      default_error_message:
        "Aswan willakuypaq pantayta llamkay qallarisqaniku, ama hina llamkay qallariy hinallataq watiqmanta ruwapay.",
    },
  },
  ro: {
    translation: {
      loading: "se încarcă",
      authorizing: "autorizare",
      error_title: "Ceva nu a funcționat corect",
      default_error_message:
        "Am înregistrat o problemă pentru investigații suplimentare, deconectați-vă și încercați din nou.",
    },
  },
  ru: {
    translation: {
      loading: "загрузка",
      authorizing: "авторизация",
      error_title: "Возникла неизвестная ошибка",
      default_error_message:
        "Мы зарегистрировали проблему для дальнейшего исследования. Выйдите из системы и повторите попытку.",
    },
  },
  rw: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  sd: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  si: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  sk: {
    translation: {
      loading: "načítava sa",
      authorizing: "autorizácia",
      error_title: "Vyskytol sa problém",
      default_error_message:
        "Zaznamenali sme problém na ďalšie preskúmanie. Odhláste sa a skúste to znova.",
    },
  },
  sl: {
    translation: {
      loading: "nalaganje",
      authorizing: "odobritev",
      error_title: "Prišlo je do napake",
      default_error_message:
        "Težavo smo prijavili za nadaljnjo preiskavo. Odjavite se in poskusite znova.",
    },
  },
  sq: {
    translation: {
      loading: "duke ngarkuar",
      authorizing: "duke autorizuar",
      error_title: "Diçka nuk shkoi mirë",
      default_error_message:
        "Ne kemi kërkuar një çështje për hetim të mëtejshëm, ju lutem dilni dhe provoni përsëri.",
    },
  },
  "sr-cyrl-ba": {
    translation: {
      loading: "учитавање",
      authorizing: "ауторизација",
      error_title: "Појавила се грешка",
      default_error_message:
        "Евидентирали смо проблем за даљњу истрагу. Одјавите се и покушајте поново.",
    },
  },
  "sr-cyrl-rs": {
    translation: {
      loading: "учитавање",
      authorizing: "ауторизација",
      error_title: "Нешто није у реду",
      default_error_message:
        "Пријавили смо проблем ради даљег испитивања, одјавите се и покушајте поново.",
    },
  },
  "sr-latn-rs": {
    translation: {
      loading: "učitavanje",
      authorizing: "davanje ovlašćenja",
      error_title: "Nešto nije u redu",
      default_error_message:
        "Prijavili smo problem radi daljeg ispitivanja, odjavite se i pokušajte ponovo.",
    },
  },
  sv: {
    translation: {
      loading: "läser in",
      authorizing: "auktoriserar",
      error_title: "Något gick fel",
      default_error_message:
        "Vi har loggat ett problem för vidare undersökning. Logga ut och försök igen.",
    },
  },
  sw: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  ta: {
    translation: {
      loading: "ஏற்றுகிறது",
      authorizing: "அங்கீகாரமளிக்கிறது",
      error_title: "ஏதோ தவறாகிவிட்டது",
      default_error_message:
        "தொடர்ச்சியான ஆய்விற்காக, நாங்கள் சிக்கலைப் பதிவுசெய்துள்ளோம், வெளியேறி, மீண்டும் முயலவும்.",
    },
  },
  te: {
    translation: {
      loading: "లోడ్",
      authorizing: "అధికారం ఇవ్వడం",
      error_title: "ఏదో తప్పు జరిగింది",
      default_error_message:
        "తదుపరి విచారణ కోసం మేము సమస్యను లాగ్ చేశాము, దయచేసి లాగ్ అవుట్ చేసి, మళ్లీ ప్రయత్నించండి.",
    },
  },
  tg: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  th: {
    translation: {
      loading: "กำลังโหลด",
      authorizing: "กำลังอนุมัติ",
      error_title: "เกิดข้อผิดพลาดบางอย่าง",
      default_error_message:
        "เราได้บันทึกปัญหาสําหรับการตรวจสอบเพิ่มเติม โปรดออกจากระบบแล้วลองอีกครั้ง",
    },
  },
  ti: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  tk: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  tn: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  tr: {
    translation: {
      loading: "yükleniyor",
      authorizing: "yetkilendiriliyor",
      error_title: "Bir sorun oluştu",
      default_error_message:
        "Sorunu daha ayrıntılı inceleme için günlüğe kaydettik, lütfen oturumu kapatın ve yeniden deneyin.",
    },
  },
  tt: {
    translation: {
      loading: "йөкләнә",
      authorizing: "вәкаләт бирү",
      error_title: "Ниндидер хата килеп чыккан",
      default_error_message:
        "Алга таба тикшерү өчен проблеманы теркәдек, системадан чыгып, омтылышны кабатлагыз.",
    },
  },
  ug: {
    translation: {
      loading: "يۈكلەۋاتىدۇ",
      authorizing: "ئىجازەتلەۋاتىدۇ",
      error_title: "خاتالىق كۆرۈلدى",
      default_error_message:
        "بىر مەسىلىنى تەپسىلىي تەكشۈرۈش ئۈچۈن تىزىملىدۇق، چېكىنىپ قايتا سىناڭ.",
    },
  },
  uk: {
    translation: {
      loading: "завантаження",
      authorizing: "авторизація",
      error_title: "Сталася помилка",
      default_error_message:
        "Ми зареєстрували проблему для подальшого розслідування. Вийдіть і спробуйте ще раз.",
    },
  },
  ur: {
    translation: {
      loading: "لوڈ ہو رہا ہے",
      authorizing: "اجازت دی جا رہی ہے",
      error_title: "کچھ غلط ہو گیا",
      default_error_message:
        "مزید تفتیش کے لئے ہم نے ایک مسئلہ لاگ کیا ہے، براہ کرم لاگ آؤٹ کرکے دوبارہ کوشش کریں۔",
    },
  },
  uz: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Qandaydir xatolik yuz berdi",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  vi: {
    translation: {
      loading: "đang tải",
      authorizing: "đang ủy quyền",
      error_title: "Đã xảy ra lỗi",
      default_error_message:
        "Chúng tôi đã ghi nhật ký sự cố để điều tra thêm, vui lòng đăng xuất rồi thử lại.",
    },
  },
  wo: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  xh: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  yo: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
  "zh-hans": {
    translation: {
      loading: "正在加载",
      authorizing: "正在授权",
      error_title: "出了点问题",
      default_error_message: "我们已记录问题以供进一步调查，请注销并重试。",
    },
  },
  "zh-hant": {
    translation: {
      loading: "正在載入",
      authorizing: "正在授權",
      error_title: "發生問題",
      default_error_message:
        "我們已記錄問題以進行進一步調查，請登出，然後再試一次。",
    },
  },
  zu: {
    translation: {
      loading: "loading",
      authorizing: "authorizing",
      error_title: "Something went wrong",
      default_error_message:
        "We have logged an issue for further investigation, please log out and try again.",
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  // use the user browser language for localization, keep the locale key all lowercase for consistency.
  lng: navigator.language.toLowerCase(),
  resources,
  fallbackLng: "en",
});

export default i18n;
