import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Environment,
  AddResource,
  pidl,
  IElementFactory,
} from "@cspayments/pidl-react";
import { FluentUIElementFactory } from "@cspayments/pidl-fluent-ui";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { CookieUtil } from "../utils/cookieUtil";
import {
  PidlConfigParams,
  AdditionalHeadersType,
} from "../schema/taxProfileMetaData.types";
import { MiscUtil } from "../utils/miscUtil";
import * as Constants from "../constant/constants";
import { PrefilldataHelper } from "../utils/prefilldataHelper";

export declare type WindowSize =
  | "fullScreen"
  | "windowsStoreModal"
  | "amcDialog";

export interface IResourceViewerState {
  returnOptions: pidl.IDisplayControlReturnOptions;
  userContext: pidl.IDisplayControlUserContext;
  displayOptions: Partial<pidl.IDisplayControlDisplayOptions>;
  telemetryOptions?: pidl.IDisplayControlTelemetryOptions;
  env: Environment;
  pidlDocInfo: pidl.IPidlDocInfo | pidl.ISelectResourcePidlDocInfo;
  prefillData?: any;
  apiOptions?: pidl.IApiOptions;
  elementFactory?: IElementFactory;
  resourceKey: any;
  callbacks?: pidl.ICallbacks;
  windowSize: WindowSize;
  ref?: React.RefObject<any>;
}

export interface PidlGeneratorRefType {
  goNext(): void;
  goBack(): void;
}

type PidlGeneratorPropsType = {
  callbackOnSuccess: (data?: any) => void;
  callbackOnFailure: (data?: any) => void;
  onPidlEvent: (eventName: string, parameters: any) => void;
  pidlConfigParams: PidlConfigParams;
  busyBlockShowHideHandler?: (isBusy: boolean) => void;
  additionalHeaders?: AdditionalHeadersType;
};

export const PidlGenerator = forwardRef<
  PidlGeneratorRefType,
  PidlGeneratorPropsType
>((props, parentRef) => {
  const {
    profile_status,
    sap_indicator,
    tax_residency_country,
    section,
    pay_from_countries,
    account_type,
    tenant_id,
    culture_name,
    pidl_prefill_data,
    otherdocumentation_selectedCountryCode,
    questionnaire_selectedquestioncode,
    bank_countries,
  } = props.pidlConfigParams;
  const pidlRef = useRef() as React.MutableRefObject<AddResource>;

  // expose goNext function and enable other component to call moveNextPage() API from PIDL SDK.
  useImperativeHandle(parentRef, () => ({
    goNext: () => {
      pidlRef.current?.moveNextPage();
    },
    goBack: () => {
      pidlRef.current?.movePrevPage();
    },
  }));

  const params: IResourceViewerState = {
    apiOptions: undefined,
    callbacks: { getPidlDocument: undefined },
    displayOptions: {
      containerId: "pidl-react-container",
      showPlaceholderInTextbox: true,
      inputPropertyIdPrefix: "",
      // prop for hiding Next/Back buttons
      showSubmitBlock: false,
      busyBlockShowHideHandler: props.busyBlockShowHideHandler,
    },
    elementFactory: new FluentUIElementFactory(),
    env:
      process.env.REACT_APP_ENV === "prod"
        ? "prod"
        : process.env.REACT_APP_ENV === "uat"
        ? "ppe"
        : "int",
    pidlDocInfo: {
      resourceType: "PayeeTaxProfile",
      parameters: {
        section: section,
        accounttype: account_type,
        payfromcc: pay_from_countries,
        residencecc: tax_residency_country,
        language: culture_name,
        tenant: tenant_id,
        sapidind: MiscUtil.isNullOrUndefined(sap_indicator) ? "no" : "yes",
        status: profile_status,
      },
      anonymousPidl: false,
    },
    prefillData: undefined,
    ref: { current: null },
    resourceKey: 1669852320640,
    returnOptions: {
      urls: {
        failure: "http://localhost:3001",
        gohome: "http://localhost:3001",
        success: "http://localhost:3001",
      },
      callbacks: {
        failure: (data) => {
          props.callbackOnFailure(data);
        },
        gohome: () => {},
        success: (data) => {
          props.callbackOnSuccess(data);
        },
      },
    },
    telemetryOptions: {
      contextData: {
        cvInitValue: "",
        flowId: "90aca12a-7327-19ad-623c-7a5779a29e12",
        partnerName: "webblends",
        scenarioId: "",
        sessionId: "33e5928f-41ae-80d2-7cdb-677bb084075e",
        userId: "",
      },
    },
    userContext: {
      additionalHeaders: {
        "x-ms-correlation-id": CookieUtil.getCookie(
          Constants.correlationIdCookieName
        )!,
        sessionid: CookieUtil.getCookie(Constants.sessionIdCookieName)!,
        userkey: CookieUtil.getCookie(Constants.userKeyCookieName)!,
        bankcountries: !MiscUtil.isNullOrUndefinedOrEmptyArray(bank_countries)
          ? bank_countries!.join(",")
          : "",
        showcti: props.additionalHeaders?.showcti || "",
        incometypecodes: props.additionalHeaders?.incometypecodes || "",
        questioncode: props.additionalHeaders?.questioncode || "",
      },
      authToken: props.additionalHeaders?.authToken || "",
      userId: "me",
    },
    windowSize: "windowsStoreModal",
  };

  const appInsights = useAppInsightsContext();
  const trackTestMsg = useTrackEvent(
    appInsights,
    "Integration Confirmatioin",
    "This is test to confirm app insight integration worked"
  );

  return (
    <AddResource
      key={params.resourceKey}
      ref={pidlRef}
      returnOptions={params.returnOptions}
      userContext={params.userContext}
      busyBlockElement={undefined}
      displayOptions={params.displayOptions}
      telemetryOptions={params.telemetryOptions}
      env={params.env}
      pidlDocInfo={params.pidlDocInfo as pidl.IPidlDocInfo}
      prefillData={{
        ...PrefilldataHelper.prefillDataMapper(
          pidl_prefill_data,
          section!,
          otherdocumentation_selectedCountryCode,
          questionnaire_selectedquestioncode
        ),
      }}
      options={params.apiOptions}
      elementFactory={params.elementFactory}
      callbacksIn={params.callbacks}
      onEvent={props.onPidlEvent}
    />
  );
});
