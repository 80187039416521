import { UiElementLabels } from "../schema/taxProfileMetaData.types";
import { MiscUtil } from "../utils/miscUtil";

type PropsType = {
  uiElementLabels: UiElementLabels;
};

export const Footer = (props: PropsType): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "1%",
        paddingRight: "1%",
      }}
    >
      <div>
        {props.uiElementLabels.footer?.map((element, index) => {
          return (
            <>
              {MiscUtil.isNullOrUndefined(element.link) ? (
                element.text
              ) : (
                <>
                  <a
                    role="link"
                    href={element.link!}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footerText"
                    style={{ textDecoration: "underline" }}
                  >
                    {element.text}
                  </a>
                  {index !== props.uiElementLabels.footer.length - 1 && (
                    <>&nbsp;&nbsp;|</>
                  )}
                </>
              )}
              &nbsp;&nbsp;
            </>
          );
        })}
      </div>
      <div>
        <img
          src="./mslogo.png"
          alt="Microsoft Logo"
          role="presentation"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};
