import axios from "axios";
import { MiscUtil } from "../utils/miscUtil";
import { CookieUtil } from "../utils/cookieUtil";
import * as Constants from "../constant/constants";
import { AuthenticationState } from "../schema/taxProfileMetaData.types";

// This is a helper class for implementing the authentication layer including cookie validation
export class Authentication {
  constructor() {}

  // User is authenticated when the sessionId is cached in the cookies.
  get isAuthenticated(): AuthenticationState {
    if (
      MiscUtil.isNullOrUndefinedOrEmptyString(
        CookieUtil.getCookie(Constants.sessionIdCookieName)
      )
    ) {
      return AuthenticationState.Unauthenticated;
    }
    return AuthenticationState.Authenticated;
  }

  async generateAuthToken() {
    // parse the sessionId from the Url
    const sessionId = MiscUtil.getQueryStringValue(
      Constants.sessionIdQueryString
    );
    // parse the ctisubmit from the Url
    const ctisubmit = MiscUtil.getQueryStringValue(
      Constants.ctiSubmitQueryString
    );
    const userKey = MiscUtil.createGuid();
    const correlationId = MiscUtil.createGuid();

    const response = await axios.get(process.env.REACT_APP_AUTH_WITH_SESSION!, {
      headers: {
        sessionid: sessionId,
        userkey: userKey,
        "x-ms-correlation-id": correlationId,
      },
    });

    // only set a new cookie when it doesn't exist and the response is valid.
    if (
      !MiscUtil.isNullOrUndefinedOrEmptyString(response.data) &&
      MiscUtil.isNullOrUndefinedOrEmptyString(
        CookieUtil.getCookie(Constants.sessionIdCookieName)
      )
    ) {
      const data = response.data;
      const timeout = data.MinutesLeft;
      const payeeId = data.PayeeId;
      const userName = data.UserName;
      const cultureName = data.CultureName;
      const returnUri = data.ReturnUri;
      const timeoutInMs = timeout * 60 * 1000;

      // set correlation id in the cookies to enable logging traceability
      CookieUtil.setCookie(
        Constants.correlationIdCookieName,
        correlationId,
        timeout
      );

      CookieUtil.setCookie(Constants.payeeIdCookieName, payeeId, timeout);
      CookieUtil.setCookie(Constants.userNameCookieName, userName, timeout);
      CookieUtil.setCookie(
        Constants.cultureNameCookieName,
        cultureName,
        timeout
      );
      CookieUtil.setCookie(
        Constants.expiryCookieName,
        (new Date().getTime() + timeoutInMs).toString(),
        timeout
      );
      CookieUtil.setCookie(Constants.sessionIdCookieName, sessionId, timeout);
      CookieUtil.setCookie(Constants.ctiSubmitCookieName, ctisubmit, timeout);
      CookieUtil.setCookie(Constants.userKeyCookieName, userKey, timeout);
      // set the returnUrl in localStorage to persist
      localStorage.setItem(Constants.returnUrlCookieName, returnUri);
    }

    return response;
  }
}
