import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

// excerpt of the config section of the JavaScript SDK snippet with correlation
// between client-side AJAX and server requests enabled.
const appInsights = new ApplicationInsights({
  config: {
    // Application Insights Configuration
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    connectionString: process.env.REACT_APP_CONNECTION_STRING,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ["*.queue.core.windows.net"],
    /* ...Other Configuration Options... */
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
