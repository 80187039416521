import axios from "axios";
import { MiscUtil } from "../utils/miscUtil";
import { CookieUtil } from "../utils/cookieUtil";
import { TaxProfileMetaData } from "../schema/taxProfileMetaData.types";
import { Authentication as AuthenticationLayer } from "../authenticaionLayer/authentication";
import * as Constants from "../constant/constants";

// This is a helper class for implementing the tax profile metadata layer.
export class TaxProfileMetaDataManager {
  private ctiSubmitted: boolean;

  constructor() {
    this.ctiSubmitted = false;
  }

  getCtiSubmitted(): boolean {
    return this.ctiSubmitted;
  }

  // data binding mapper function to bind metadata into a concise format that can be used
  // in the react component.
  public taxProfileMetaDataParser(data: any): Partial<TaxProfileMetaData> {
    try {
      return {
        browser_warning: data.browser_warning || "",
        validation_message: data.validation_message || "",
        user_name: data.user_name || "",
        email: data.email || "",
        return_url: data.return_url || "",
        session_timeout: data.session_timeout || 20,
        pidl_prefill_data: data.pidl_prefill_data || {},
        culture_name: data.culture_name || "",
        navigation_metadata: data.navigation_metadata || [],
        // non-nullable assertion for PIDL SDK params
        profile_status: data.profile_status!,
        sap_indicator: data.sap_indicator!,
        tax_residency_country: data.tax_residency_country!,
        pay_from_countries: data.pay_from_countries!,
        account_type: data.account_type!,
        tenant_id: data.tenant_id!,
        country_code_vat_types: data.country_code_vat_types!,
        payfrom_country_details: data.payfrom_country_details!,
        payee_additional_info: data.payee_additional_info!,
        country_income_codes: data.country_income_codes!,
        ui_element_labels: data.ui_element_labels!,
        uploadDocument_help_message1: data.UploadDocument_help_message1!,
        uploadDocument_help_message2: data.UploadDocument_help_message2!,
        uploadDocument_help_message3: data.UploadDocument_help_message3!,
        questionnaire_help_message1: data.questionnaire_help_message1!,
        questionnaire_help_message2: data.questionnaire_help_message2!,
        questionnaire_help_message3: data.questionnaire_help_message3!,
        otherdocumentation_selectedCountryCode:
          data.otherdocumentation_selectedCountryCode!,
        questionnaire_selectedquestioncode:
          data.questionnaire_selectedquestioncode!,
        session_expires_at: data.session_expires_at!,
        bank_countries: data.bank_countries!,
        questionnaire_details: data.questionnaire_details!,
      };
    } catch (e) {
      /* handle error */
      console.log("metadata parser failed");
      // Exit the program is the metadata parser failed
      throw new Error("metadata parser failed");
    }
  }

  async populateMetaData() {
    const sessionId = CookieUtil.getCookie(Constants.sessionIdCookieName);
    const ctisubmit = CookieUtil.getCookie(Constants.ctiSubmitCookieName);

    if (!MiscUtil.isNullOrUndefinedOrEmptyString(sessionId)) {
      const payeeId = CookieUtil.getCookie(Constants.payeeIdCookieName);
      const userName = CookieUtil.getCookie(Constants.userNameCookieName);
      const correlationId = CookieUtil.getCookie(
        Constants.correlationIdCookieName
      );
      const userKey = CookieUtil.getCookie(Constants.userKeyCookieName);

      return await axios.get<TaxProfileMetaData>(
        process.env.REACT_APP_PAYEE_METADATA_URL!,
        {
          headers: MiscUtil.isNullOrUndefinedOrEmptyString(ctisubmit)
            ? {
                sessionid: sessionId,
                userkey: userKey,
                payeeid: payeeId,
                username: userName,
                "x-ms-correlation-id": correlationId,
              }
            : {
                sessionid: sessionId,
                userkey: userKey,
                payeeid: payeeId,
                username: userName,
                "x-ms-correlation-id": correlationId,
                ctisubmit: ctisubmit,
              },
        }
      );
    }
  }
}
