import {
  CoherenceCustomizations,
  CoherenceTheme,
} from "@coherence-design-system/styles";
import { ThemeProvider, loadTheme } from "@fluentui/react";
import React from "react";
import "./App.css";
import { TaxProfileOrchestration } from "./components/taxProfileOrchestration";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./telemetry/appInsights";

export const App: React.FunctionComponent = () => {
  loadTheme(CoherenceTheme);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider {...CoherenceCustomizations}>
        <TaxProfileOrchestration />
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
};
