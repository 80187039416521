import { SectionName } from "../schema/taxProfileMetaData.types";
import { MiscUtil } from "./miscUtil";

type DocumentationType = {
  pay_from_country_iso_code: string;
  [key: string]: any;
};

type QuestionnaireType = {
  questioncode: string;
  [key: string]: any;
};

export class PrefilldataHelper {
  // helper function only return corresponding prefill data based on the section name.
  public static prefillDataMapper(
    data: any,
    section: string,
    selectedCountry: string = "",
    selectedQuestionnaireCode: string = ""
  ): any {
    let prefillData;

    for (const [key, value] of Object.entries(data)) {
      // strip extra chars and convert to lowercase in prefilldata key to compare with pidl_metadata_phase_id
      if (key.replace(/_/g, "").toLowerCase() === section?.toLowerCase()) {
        // apply filtering logic based on pay_from_country_iso_code on otherDocumentation section.
        if (
          section.toLowerCase() === SectionName.OtherDocumentation &&
          !MiscUtil.isNullOrUndefinedOrEmptyString(selectedCountry)
        ) {
          const selectedCountryData = (value as DocumentationType[]).filter(
            (node) =>
              (node as DocumentationType).pay_from_country_iso_code ===
              selectedCountry
          );
          prefillData = selectedCountryData.at(0);
        } else if (
          section.toLowerCase() === SectionName.Questionnaire &&
          !MiscUtil.isNullOrUndefinedOrEmptyString(selectedQuestionnaireCode)
        ) {
          const selectedQuestionnaireData = (
            value as QuestionnaireType[]
          ).filter(
            (node) =>
              (node as QuestionnaireType).questioncode ===
              selectedQuestionnaireCode
          );
          prefillData = selectedQuestionnaireData.at(0);
        } else {
          prefillData = value;
        }
      }
    }

    return prefillData;
  }
}
