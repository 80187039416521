export const unauthErrorCode: number = 401;
export const sessionIdCookieName: string = "taxprofile_session_id";
export const ctiSubmitCookieName: string = "taxprofile_ctisubmit";
export const userKeyCookieName: string = "taxprofile_user_key";
export const userNameCookieName: string = "taxprofile_user_name";
export const payeeIdCookieName: string = "taxprofile_payee_id";
export const cultureNameCookieName: string = "taxprofile_culture_name";
export const expiryCookieName: string = "taxprofile_expiry";
export const returnUrlCookieName: string = "taxprofile_return_url";
export const correlationIdCookieName: string = "taxprofile_correlation_id";
export const otherDocumentationPageId: string = "other_documentation_page";
export const questionnairePageId: string = "questionnaire_page";
export const taxFormPageId: string = "us_tax_documentation_page";
export const otherDocumentationPhaseId: string = "OtherDocumentation";
export const questionnairePhaseId: string = "Questionnaire";
export const sessionIdQueryString: string = "SessionId";
export const ctiSubmitQueryString: string = "ctisubmit";
export const fileUploadFailed: string = "FileUploadFailed";
