import React, { forwardRef, useState, useEffect } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  getTheme,
  FontWeights,
  mergeStyleSets,
  IButtonStyles,
  IIconProps,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Link,
  Text,
  Modal,
} from "@fluentui/react";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { PidlGenerator, PidlGeneratorRefType } from "./pidlGenerator";
import { MiscUtil } from "../utils/miscUtil";
import {
  PidlConfigParams,
  LeftNavigation,
} from "../schema/taxProfileMetaData.types";

interface AdditionalTaxIdentifierRefType {
  goNext(): void;
  goBack(): void;
}

type AdditionalTaxIdentifierPropsType = {
  prefillData: any;
  callbackOnSuccess: () => void;
  callbackOnFailure: (data?: any) => void;
  onPidlEvent: (eventName: string, parameters: any) => void;
  pidlConfigParams: PidlConfigParams;
  leftNavigation: LeftNavigation;
};

type AdditionalTaxIdentifierStateType = {
  country: string;
  id: string;
  idDisplay: string;
  idCode: string;
};

// styling related definitions
const theme = getTheme();
const parentStackTokens: IStackTokens = {
  childrenGap: 20,
  maxWidth: 600,
};
const buttonStackToken: IStackTokens = {
  childrenGap: 15,
  maxWidth: 100,
};
const addDialogContentProps = (title?: string) => {
  return {
    type: DialogType.normal,
    title: title,
  };
};
const dialogStyles = { main: { maxWidth: 450 } };
const cancelIcon: IIconProps = { iconName: "Cancel" };
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    // display the list into 4 columns
    columns: "4 auto",
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

// helper function for parsing param stirngs to an array of type AdditionalTaxIdentifierStateType format for UI rendering
const countryIdParser = (
  countries_string: string,
  ids_string: string,
  idDisplay_string: string,
  idCode_string: string
): AdditionalTaxIdentifierStateType[] => {
  // short-circuit the function if any of the string is empty.
  if (
    MiscUtil.isNullOrUndefinedOrEmptyString(countries_string) ||
    MiscUtil.isNullOrUndefinedOrEmptyString(ids_string) ||
    MiscUtil.isNullOrUndefinedOrEmptyString(idDisplay_string) ||
    MiscUtil.isNullOrUndefinedOrEmptyString(idCode_string)
  ) {
    return [];
  }

  const countries: string[] = countries_string.split("|");
  const ids: string[] = ids_string.split("|");
  const idDisplays: string[] = idDisplay_string.split("|");
  const idCodes: string[] = idCode_string.split("|");

  // throw an error when the length do not match as they should always be a pair.
  if (
    !(
      countries.length === ids.length &&
      ids.length === idDisplays.length &&
      idDisplays.length === idCodes.length
    )
  ) {
    throw new Error("The length of countries and ids do not match");
  }
  // short-circuit the function if any of the array is empty.
  if (
    countries.length === 0 ||
    ids.length === 0 ||
    idDisplays.length === 0 ||
    idCodes.length === 0
  ) {
    return [];
  }

  let res: AdditionalTaxIdentifierStateType[] = [];
  countries.forEach((country, index) => {
    res.push({
      country: country,
      id: ids[index],
      idDisplay: idDisplays[index],
      idCode: idCodes[index],
    });
  });

  return res;
};

// helper function for converting an array of AdditionalTaxIdentifierStateType into an array of string for saving to backend later
const convertToPipeStrings = (
  data: AdditionalTaxIdentifierStateType[]
): string[] => {
  let countires: string[] = [];
  let ids: string[] = [];
  let idDisplays: string[] = [];
  let idCodes: string[] = [];

  data.forEach((node, index) => {
    countires.push(node.country);
    ids.push(node.id);
    idDisplays.push(node.idDisplay);
    idCodes.push(node.idCode);
  });

  let res: string[] = [];
  res.push(countires.join("|"));
  res.push(ids.join("|"));
  res.push(idDisplays.join("|"));
  res.push(idCodes.join("|"));

  return res;
};

export const AdditionalTaxIdentifier = forwardRef<
  AdditionalTaxIdentifierRefType,
  AdditionalTaxIdentifierPropsType
>((props, parentRef) => {
  let addVatIdDialogRef: PidlGeneratorRefType;

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId("title");

  const { prefillData } = props;
  const [vatCountryIdMapper, setVatCountryIdMapper] = useState<
    AdditionalTaxIdentifierStateType[]
  >(
    countryIdParser(
      prefillData.vat_identifier_country_names,
      prefillData.vat_identifiers,
      prefillData.vat_identifier_type_names,
      prefillData.vat_identifier_type_codes
    )
  );
  const [pidlConfigParamState, setPidlConfigParamState] =
    useState<PidlConfigParams>(props.pidlConfigParams);
  // state controls the dialog toggle
  const [hideAddDialog, { toggle: toggleHideAddDialog }] = useBoolean(true);
  const [hideCountryListModal, { toggle: toggleCountryListModal }] =
    useBoolean(true);
  // state controls the dialog loading
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  const handleAddClick = () => {
    toggleHideAddDialog();
  };

  const handleCountryList = () => {
    toggleCountryListModal();
  };

  // click handler will trigger goNext function from the resouceView ref
  const clickSaveButton = () => {
    addVatIdDialogRef?.goNext();
  };

  const handleRemoveClick = (index: number) => {
    const countryIdPair = [...vatCountryIdMapper];
    countryIdPair.splice(index, 1);
    setVatCountryIdMapper(countryIdPair);
  };

  const busyBlockShowHideHandler = (isBusy: boolean) => {
    // trigger/stop loading in the dialog based on callback param isBusy.
    setIsDialogLoading(isBusy);
  };

  const callbackOnSuccess = (data: any) => {
    toggleHideAddDialog();

    const country_code_vat_types = pidlConfigParamState.country_code_vat_types;
    const vatCountry = data.vat_identifier_country;
    const vatId = data.vat_identifier_name;
    // infer the idDisplay & idCode based on country selected
    const [idDisplay, idCode] = country_code_vat_types[vatCountry].split("|");

    const node = [...vatCountryIdMapper];
    // replace the vat Id if the country is found, else add to the obj
    const ind = node.findIndex((pair) => pair.country === vatCountry);
    if (ind === -1) {
      node.push({
        country: vatCountry,
        id: vatId,
        idDisplay: idDisplay,
        idCode: idCode,
      });
    } else {
      node[ind].id = vatId;
    }

    setVatCountryIdMapper(node);
  };

  const updatePrefilldataOnSubmit = () => {
    const [countries, ids, idDisplays, idCodes] =
      convertToPipeStrings(vatCountryIdMapper);

    setPidlConfigParamState({
      ...pidlConfigParamState,
      pidl_prefill_data: {
        ...pidlConfigParamState.pidl_prefill_data,
        additional_tax_identifiers: {
          ...pidlConfigParamState.pidl_prefill_data.additional_tax_identifiers,
          vat_identifiers: ids,
          vat_identifier_type_codes: idCodes,
          vat_identifier_type_names: idDisplays,
          vat_identifier_country_names: countries,
        },
      },
    });
  };

  useEffect(() => {
    updatePrefilldataOnSubmit();
  }, [vatCountryIdMapper]);

  return (
    <Stack tokens={parentStackTokens}>
      {!MiscUtil.isNullOrUndefinedorEmptyObject(props.leftNavigation) && (
        <>
          <Text>{props.leftNavigation?.description}</Text>
          <Link onClick={handleCountryList} underline>
            {props.leftNavigation?.country_list_display}
          </Link>
          <Stack tokens={buttonStackToken}>
            <PrimaryButton
              text={props.leftNavigation?.add_button_text!}
              onClick={handleAddClick}
            />
          </Stack>
          {!MiscUtil.isNullOrUndefinedOrEmptyArray(vatCountryIdMapper) && (
            <Stack horizontal disableShrink={true} tokens={parentStackTokens}>
              <Stack verticalAlign="space-around" horizontalAlign="start">
                <strong>{props.leftNavigation?.country_label}</strong>
                {vatCountryIdMapper.map((mapper, i) => {
                  return <span>{mapper.country}</span>;
                })}
              </Stack>
              <Stack verticalAlign="space-around" horizontalAlign="center">
                <strong>{props.leftNavigation?.tax_identifier_label}</strong>
                {vatCountryIdMapper.map((mapper, i) => {
                  return <span>{mapper.idDisplay}</span>;
                })}
              </Stack>
              <Stack verticalAlign="space-around">
                <Stack horizontalAlign="center">
                  <strong>{props.leftNavigation?.identifier_label}</strong>
                </Stack>
                <Stack verticalAlign="space-around" horizontalAlign="end">
                  {vatCountryIdMapper.map((mapper, i) => {
                    return (
                      <span>
                        {mapper.id}
                        <IconButton
                          iconProps={{ iconName: "Delete" }}
                          title="Delete"
                          onClick={() => handleRemoveClick(i)}
                        />
                      </span>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          )}
          <Modal
            titleAriaId={titleId}
            isOpen={!hideCountryListModal}
            onDismiss={toggleCountryListModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
            dragOptions={undefined}
          >
            <div className={contentStyles.header}>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={toggleCountryListModal}
              />
            </div>
            <div className={contentStyles.body}>
              {Object.keys(pidlConfigParamState.country_code_vat_types).map(
                (key, i) => {
                  return (
                    <p>
                      {/* use the last value of the pipe saperate values as the localized country string. */}
                      {
                        pidlConfigParamState.country_code_vat_types[key].split(
                          "|"
                        )[2]
                      }
                    </p>
                  );
                }
              )}
            </div>
          </Modal>
          <Dialog
            hidden={hideAddDialog}
            onDismiss={toggleHideAddDialog}
            dialogContentProps={addDialogContentProps(
              props.leftNavigation?.add_tax_identifier_title!
            )}
            modalProps={{
              isBlocking: true,
              styles: dialogStyles,
              topOffsetFixed: true,
            }}
          >
            {!hideAddDialog && (
              <>
                <PidlGenerator
                  ref={(pidlGeneratorRef) => {
                    if (pidlGeneratorRef) {
                      addVatIdDialogRef = pidlGeneratorRef;
                    }
                  }}
                  // override callback with local function instead of passing callback function from parent component.
                  callbackOnSuccess={callbackOnSuccess}
                  // propagate the error event back to parent compoent for error banner display.
                  callbackOnFailure={props.callbackOnFailure}
                  onPidlEvent={props.onPidlEvent}
                  pidlConfigParams={{
                    ...props.pidlConfigParams,
                    section: "nonresidentvat",
                  }}
                  busyBlockShowHideHandler={busyBlockShowHideHandler}
                />
                {isDialogLoading && (
                  <Spinner label={props.leftNavigation?.pidl_laoding_text!} />
                )}
              </>
            )}
            <DialogFooter>
              <PrimaryButton
                onClick={clickSaveButton}
                text={props.leftNavigation.save_button_text!}
              />
              <DefaultButton
                onClick={toggleHideAddDialog}
                text={props.leftNavigation.cancel_button_text!}
              />
            </DialogFooter>
          </Dialog>
          <PidlGenerator
            ref={parentRef}
            callbackOnSuccess={props.callbackOnSuccess}
            callbackOnFailure={props.callbackOnFailure}
            onPidlEvent={props.onPidlEvent}
            pidlConfigParams={pidlConfigParamState}
            // trigger DOM re-rendering on PIDL SDK by the update of pidlConfigParamState.
            key={JSON.stringify(pidlConfigParamState)}
          />
        </>
      )}
    </Stack>
  );
});
