import {
  ErrorStateComponent,
  ErrorStateIconType,
} from "@coherence-design-system/controls";
import { TaxProfileRequestError } from "../schema/taxProfileMetaData.types";
import { useTranslation } from "react-i18next";

type errorProps = {
  requestError: TaxProfileRequestError;
};

export const ErrorAbstractionPage = (props: errorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ErrorStateComponent
      // If the request error title does not exist, use pre-loading error title instead
      headingText={props.requestError.title || t("error_title")!}
      // If the request error message does not exist, use pre-loading error message instead
      descriptionText={
        props.requestError.message || t("default_error_message")!
      }
      iconType={ErrorStateIconType.OrangeCap}
    />
  );
};
