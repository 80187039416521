import axios from "axios";
import React, { FC, useState, useEffect, useReducer } from "react";
import { pidl } from "@cspayments/pidl-react";
import {
  canGoToNextStep,
  canGoToPreviousStep,
  CoherenceWizard,
  goToPreviousStep,
  IWizardProps,
  IWizardStepButtonProps,
  IWizardStepProps,
  SubwayNavNodeState,
  WizardActionBar,
  WizardType,
  CoherenceLoading,
  CoherenceHeader,
  FarRightProfilePanelProps,
} from "@coherence-design-system/controls";
import {
  classNamesFunction,
  IStyle,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { Authentication as AuthenticationLayer } from "../authenticaionLayer/authentication";
import { TaxProfileMetaDataManager } from "../metaDataManager/metaDataManager";
import { ResourceView, ResourceViewRefType } from "./resourceView";
import { ErrorAbstractionPage } from "./errorAbstractionPage";
import {
  TaxProfileMetaData,
  LeftNavigation,
  UiElementLabels,
  PidlConfigParams,
  AuthenticationState as AuthenticationStateEnum,
  TaxProfileRequestError,
  EventName,
} from "../schema/taxProfileMetaData.types";
import { MiscUtil } from "../utils/miscUtil";
import { CookieUtil } from "../utils/cookieUtil";
import * as Constants from "../constant/constants";
import { Footer } from "./footer";
import "../i18n";
import { useTranslation } from "react-i18next";

interface TaxProfileOrchestrationStyle {
  containerHeight: IStyle;
}

interface TaxProfileOrchestrationProps {}

enum MessageType {
  ServiceError,
  ValidationWarning,
  BrowserWarning,
}

// Normally this component would fill an entire page. This is to help it make sense in the context of the example app.
const getStyles = (): TaxProfileOrchestrationStyle => {
  return {
    containerHeight: {},
  };
};

const dialogStyles = { main: { maxWidth: 450 } };

const loadingStyles: React.CSSProperties = {
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  opacity: 0.7,
  backgroundColor: "white",
};

const cancelDialogContentProps = (
  cancel_title: string,
  cancel_message: string
) => {
  return {
    type: DialogType.normal,
    title: cancel_title,
    closeButtonAriaLabel: "close",
    subText: cancel_message,
  };
};

const timeoutDialogContentProps = (
  timeout_title: string,
  timeout_message: string
) => {
  return {
    type: DialogType.normal,
    title: timeout_title,
    closeButtonAriaLabel: "redirect",
    subText: timeout_message,
  };
};

const getClassNames = classNamesFunction<{}, TaxProfileOrchestrationStyle>();
const classNames = getClassNames(getStyles!);

/* In production, you'll want to make sure this object is localized
   This helps screen readers announce the status of the steps in the subway nav */
const subwayNavNodeAriaLabels = {
  NotStarted: "Not Started",
  Current: "Current",
  CurrentWithSubSteps: "Current With Sub Steps",
  Completed: "Completed",
  ViewedNotCompleted: "Viewed Not Completed",
  Unsaved: "Unsaved",
  Skipped: "Skipped",
  Error: "Error",
  WizardComplete: "Wizard Complete",
};

const commonWizardProps = {
  subwayNavProps: { stateAriaLabels: subwayNavNodeAriaLabels },
  navAriaLabel: "Custom label for Wizard navigation",
};

const getProfilePanelSettings = (name: string): FarRightProfilePanelProps => ({
  panelSettings: {
    titleText: "Profile",
    fullName: name,
    emailAddress: undefined,
    imageUrl: undefined,
    logOutLink: "#",
    hideSignOut: true,
    hideSignOutDivider: true,
  },
});

export const TaxProfileOrchestration: FC<TaxProfileOrchestrationProps> = () => {
  const { t } = useTranslation();

  let resourceViewRef: ResourceViewRefType;

  // AuthenticationLayer & MetadataLayer initialization
  const [authenticationLayer] = useState(() => new AuthenticationLayer());
  const [taxProfileMetaDataManager] = useState(
    () => new TaxProfileMetaDataManager()
  );
  // Authentication state
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationStateEnum>(authenticationLayer.isAuthenticated);
  // metadata state for rendering/controling UI.
  const [navigationMetaData, setNavigationMetaData] = useState<
    LeftNavigation[]
  >([]);
  // UI labels state
  const [uiElementLabels, setUiElementLabels] = useState<UiElementLabels>(
    {} as UiElementLabels
  );
  // expiry state
  const [sessionExpiry, setSessionExpiry] = useState<string>("");
  const [pidlConfigParams, setPidlConfigParams] = useState<PidlConfigParams>(
    {} as PidlConfigParams
  );
  // Dialog controls
  const [hideTimeOutDialog, { toggle: toggleHideTimeOutDialog }] =
    useBoolean(true);
  const [hideCancelDialog, { toggle: toggleHideCancelDialog }] =
    useBoolean(true);
  // error message banner control
  const [serviceErrorMessage, setServiceErrorMessage] = useState<string>("");
  // validation message banner control
  const [validationWarningMessage, setValidationWarningMessage] =
    useState<string>("");
  // browser message banner control
  const [browserWarningMessage, setBrowserWarningMessage] =
    useState<string>("");
  // Error object for populating the error UI page.
  const [displayErrorAbstraction, setDisplayErrorAbstraction] =
    useState<TaxProfileRequestError>({} as TaxProfileRequestError);

  /**
   using useReducer this way basically ensures that any time you call
   setInstanceKey, the `instanceKey` is set to a new object which will
   make the `key` different resulting in React unmounting the previous
   component and mounting a new one.

   NOTE: reference - https://kentcdodds.com/blog/understanding-reacts-key-prop
   **/
  const [instanceKey, setInstanceKey] = useReducer((c) => c + 1, 0);

  // helper function for parsing the error object for Axios calls
  const parseErrorAbstraction = (error: any): TaxProfileRequestError => {
    let res: TaxProfileRequestError = {} as TaxProfileRequestError;
    const requestError = error?.response?.data?.error;
    res.message = requestError?.message;
    return res;
  };

  useEffect(() => {
    /**
      1. authenticate the user first
      2. get the session data
      3. retrieve metadata and populate the data model for easy access
     **/
    async function initializer() {
      console.log(
        "authenticationLayer is authenticated",
        authenticationLayer.isAuthenticated ===
          AuthenticationStateEnum.Authenticated
      );

      // re-authenticate if the Authentication token does not exist
      const sessionId = MiscUtil.getQueryStringValue("SessionId");
      if (
        authenticationLayer.isAuthenticated ===
        AuthenticationStateEnum.Unauthenticated
      ) {
        // indicate the user authentication is pending
        setAuthenticationState(AuthenticationStateEnum.Authenticating);

        await authenticationLayer
          .generateAuthToken()
          .then((data) => {
            // notify UI that the Authentication token has been refreshed, and refresh the isAuthenticated state
            setAuthenticationState(authenticationLayer.isAuthenticated);
          })
          .catch((err) => {
            callbackOnFailure({
              ...err,
              isAxios: true,
            });
          });
      } else if (
        // re-authenticate if the sessionId has been updated.
        sessionId !== CookieUtil.getCookie(Constants.sessionIdCookieName)
      ) {
        // clear the existing cookies
        CookieUtil.resetCookie();

        // authenticate the user with the new session id
        setAuthenticationState(AuthenticationStateEnum.Authenticating);

        await authenticationLayer
          .generateAuthToken()
          .then((data) => {
            // notify UI that the Authentication token has been refreshed, and refresh the isAuthenticated state
            setAuthenticationState(authenticationLayer.isAuthenticated);
          })
          .catch((err) => {
            callbackOnFailure({
              ...err,
              isAxios: true,
            });
          });
      }

      // now the user is authenticated, retrieve metadata
      await taxProfileMetaDataManager
        .populateMetaData()
        .then((res) => {
          // clear ctisubmit cookie on success to avoid redundent call
          CookieUtil.deleteCookie(Constants.ctiSubmitCookieName);

          if (res) {
            // bind to data model, only partial data is needed to loasd the UI.
            const data: Partial<TaxProfileMetaData> =
              taxProfileMetaDataManager.taxProfileMetaDataParser(res.data);

            setPidlConfigParams({
              account_type: data.account_type!,
              tax_residency_country: data.tax_residency_country!,
              pay_from_countries: data.pay_from_countries!,
              payfrom_country_details: data.payfrom_country_details!,
              culture_name: data.culture_name!,
              tenant_id: data.tenant_id!,
              sap_indicator: data.sap_indicator!,
              profile_status: data.profile_status!,
              pidl_prefill_data: data.pidl_prefill_data!,
              country_code_vat_types: data.country_code_vat_types!,
              payee_additional_info: data.payee_additional_info!,
              country_income_codes: data.country_income_codes!,
              uploadDocument_help_message1: data.uploadDocument_help_message1!,
              uploadDocument_help_message2: data.uploadDocument_help_message2!,
              uploadDocument_help_message3: data.uploadDocument_help_message3!,
              questionnaire_help_message1: data.questionnaire_help_message1!,
              questionnaire_help_message2: data.questionnaire_help_message2!,
              questionnaire_help_message3: data.questionnaire_help_message3!,
              otherdocumentation_selectedCountryCode:
                data.otherdocumentation_selectedCountryCode!,
              questionnaire_selectedquestioncode:
                data.questionnaire_selectedquestioncode!,
              bank_countries: data.bank_countries!,
              questionnaire_details: data.questionnaire_details!,
            });

            // set the navigationMetaData state to trigger initial UI render
            setNavigationMetaData(data.navigation_metadata!);

            // set the ui labels state
            setUiElementLabels(data.ui_element_labels!);

            // set the expiy time
            setSessionExpiry(data.session_expires_at!);

            // set validation message
            setValidationWarningMessage(data.validation_message!);

            // set validation message
            setBrowserWarningMessage(data.browser_warning!);
          }
        })
        .catch((err) => {
          // render the error UI with error message
          setDisplayErrorAbstraction(parseErrorAbstraction(err));
        });
    }

    initializer();
  }, []);

  // added session timeout and display a dialog upon expiry.
  useEffect(() => {
    if (
      authenticationState === AuthenticationStateEnum.Authenticated &&
      !MiscUtil.isNullOrUndefinedorEmptyObject(uiElementLabels) &&
      !MiscUtil.isNullOrUndefinedOrEmptyString(sessionExpiry)
    ) {
      // convert ISO time to milisecionds
      const expiry = new Date(sessionExpiry).getTime();
      const timeToLive: number = expiry - new Date().getTime();

      console.log(
        `the session will expire in ${timeToLive / (60 * 1000)} minutes`
      );

      const timer = setTimeout(() => {
        // display the timeout dialog
        toggleHideTimeOutDialog();

        // set the screen to unauthorized upon cookie expiry
        setDisplayErrorAbstraction({
          title: uiElementLabels.timeout_error_title,
          message: uiElementLabels.timeout_error_message,
        });
        setAuthenticationState(AuthenticationStateEnum.Unauthenticated);
      }, timeToLive);

      // refres all the exisitng cookeis with new TTL
      CookieUtil.refreshCookie(timeToLive / (60 * 1000));

      return () => clearTimeout(timer);
    }
  }, [sessionExpiry, uiElementLabels]);

  // click handler will trigger goNext function from the resouceView ref
  const clickNextHandler = () => {
    const ind = findCurrentIndex(wizardProps.steps);
    const step = wizardProps.steps[ind].pidl_metadata_phase_id;

    // implement custom moveNext logic for otherdocumentation & questionnaire
    if (
      step === Constants.otherDocumentationPhaseId ||
      step === Constants.questionnairePhaseId
    ) {
      // disable footer after next is clicked
      toggleLoadingFooter(true);
      const ind: number = findCurrentIndex(wizardProps.steps);
      const loadingTextOnSave: string =
        navigationMetaData[ind].save_message || "";
      // disable left navigation and rendering intermediate loading scree for save
      setWizardProps({
        ...wizardProps,
        isLoading: true,
        loadingPaneProps: {
          primaryLoadingText: loadingTextOnSave,
        },
      });

      const payeeId = CookieUtil.getCookie(Constants.payeeIdCookieName);
      const sessionId = CookieUtil.getCookie(Constants.sessionIdCookieName);
      const userKey = CookieUtil.getCookie(Constants.userKeyCookieName);
      const correlationId = CookieUtil.getCookie(
        Constants.correlationIdCookieName
      );

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/saveuserdetails?section=${
            step === Constants.otherDocumentationPhaseId
              ? "otherdocumentation"
              : "questionnaire"
          }&isnext=true`,
          {},
          {
            headers: {
              sessionid: sessionId,
              userkey: userKey,
              payeeid: payeeId,
              "x-ms-correlation-id": correlationId,
            },
          }
        )
        .then((res) => {
          callbackOnSuccess({
            ...res,
            isNext: true,
          });
        })
        .catch((err) => {
          callbackOnFailure({
            ...err,
            isAxios: true,
          });
        });
    } else {
      resourceViewRef?.goNext();
    }
  };

  // click handler will trigger goBack function from the resouceView ref
  const clickBackHandler = () => {
    resourceViewRef?.goBack();
  };

  // helper function that finds the index of the step being marked as current
  const findCurrentIndex = (steps: IWizardStepProps[]): number => {
    const currentStepIndex = steps.findIndex(
      (step) => step.state === "Current"
    );
    return currentStepIndex;
  };

  /**
   * helper function for determining what footer to show based on the id of the step.
   * @param id        - The id of the step
   * @param isLoading - Controls is the footer is in loading state
   * @returns the footer component
   */
  const getFooterById = (id: number, isLoading: boolean = false) => {
    // handle the edge case where only one step shown, display a complete button with back disabled
    if (navigationMetaData.length === 1) {
      return lastFooter(isLoading, true);
    }
    if (id === 0) {
      return _getRegularFirstFooter(isLoading);
    } else if (id === navigationMetaData.length - 1) {
      return lastFooter(isLoading);
    } else {
      return inBetweenFooter(isLoading);
    }
  };

  // helper function for enabling/disabling footer.
  const toggleLoadingFooter = (loading: boolean) => {
    const ind = findCurrentIndex(wizardProps.steps);
    wizardProps.steps[ind].footerElement = getFooterById(ind, loading);
  };

  const _goToNextStepAction = (): IWizardStepButtonProps => {
    return {
      text: uiElementLabels.next_button_text,
      onClick: () => {
        // if we are at the end nextStep is undefined and no action is taken
        if (canGoToNextStep(wizardProps.steps)) {
          clickNextHandler();
        }
      },
    };
  };

  const _goToCompleteStepAction = (): IWizardStepButtonProps => {
    return {
      text: uiElementLabels.complete_button_text,
      onClick: () => {
        // trigger PIDL action to submit final data
        clickNextHandler();
      },
    };
  };

  const _goToPrevStepAction = (): IWizardStepButtonProps => {
    return {
      text: uiElementLabels.back_button_text,
      onClick: () => {
        // if we are at the beginning, previous steps is undefined and no action is taken
        if (canGoToPreviousStep(wizardProps.steps)) {
          clickBackHandler();

          // Most consumers would perform some logic here to decide which state is correct.
          const { steps, newCurrentStep: previousOrNextStep } =
            goToPreviousStep(
              wizardProps.steps,
              SubwayNavNodeState.ViewedNotCompleted
            );

          if (steps) {
            // disable footer after next is clicked
            toggleLoadingFooter(true);

            const ind: number = findCurrentIndex(wizardProps.steps);
            const backTextOnNext: string =
              navigationMetaData[ind].back_message || "";

            wizardProps.steps = steps;
            // disable left navigation and rendering intermediate loading screen for getPidlDocument
            setWizardProps({
              ...wizardProps,
              isLoading: true,
              loadingPaneProps: {
                primaryLoadingText: backTextOnNext,
              },
            });

            // reset current step index in navigationMetaData state
            navigationMetaData[ind - 1].is_active = true;
            navigationMetaData[ind].is_active = false;

            console.log("Now at step : " + previousOrNextStep!.label);
          } else {
            console.log(
              "We are experiencing an error when trying to go to the next steps, please try again"
            );
          }
        }
      },
    };
  };

  const _getRegularFirstFooter = (isLoading: boolean) => {
    return (
      <WizardActionBar
        isLoading={isLoading}
        mainAction={_goToNextStepAction()}
        cancelAction={_goToCancelStepAction()}
        currentStep={{} as IWizardStepProps}
      />
    );
  };

  const _goToCancelStepAction = (): IWizardStepButtonProps => {
    return {
      text: uiElementLabels.cancel_button_text,
      onClick: () => {
        // display pop for confirmation
        toggleHideCancelDialog();
      },
    };
  };

  /**
   * helper function for refreshing metadata
   * @param isFileUpload - A boolean determines if the refreshMetadata is triggered by file upload
   * @returns None
   */
  const refreshMetadata = (isFileUpload: boolean) => {
    // once the user is authenticated, populate taxProfileMetaData
    taxProfileMetaDataManager
      .populateMetaData()
      .then((response) => {
        if (response) {
          const bindedMetaData: Partial<TaxProfileMetaData> =
            taxProfileMetaDataManager.taxProfileMetaDataParser(response.data);

          // refresh the prefill data on success callback
          if (bindedMetaData.pidl_prefill_data) {
            setPidlConfigParams({
              account_type: bindedMetaData.account_type!,
              tax_residency_country: bindedMetaData.tax_residency_country!,
              pay_from_countries: bindedMetaData.pay_from_countries!,
              payfrom_country_details: bindedMetaData.payfrom_country_details!,
              culture_name: bindedMetaData.culture_name!,
              tenant_id: bindedMetaData.tenant_id!,
              sap_indicator: bindedMetaData.sap_indicator!,
              profile_status: bindedMetaData.profile_status!,
              pidl_prefill_data: bindedMetaData.pidl_prefill_data!,
              country_code_vat_types: bindedMetaData.country_code_vat_types!,
              payee_additional_info: bindedMetaData.payee_additional_info!,
              country_income_codes: bindedMetaData.country_income_codes!,
              uploadDocument_help_message1:
                bindedMetaData.uploadDocument_help_message1!,
              uploadDocument_help_message2:
                bindedMetaData.uploadDocument_help_message2!,
              uploadDocument_help_message3:
                bindedMetaData.uploadDocument_help_message3!,
              questionnaire_help_message1:
                bindedMetaData.questionnaire_help_message1!,
              questionnaire_help_message2:
                bindedMetaData.questionnaire_help_message2!,
              questionnaire_help_message3:
                bindedMetaData.questionnaire_help_message3!,
              otherdocumentation_selectedCountryCode:
                bindedMetaData.otherdocumentation_selectedCountryCode!,
              questionnaire_selectedquestioncode:
                bindedMetaData.questionnaire_selectedquestioncode!,
              bank_countries: bindedMetaData.bank_countries!,
              questionnaire_details: bindedMetaData.questionnaire_details!,
            });
          }

          // set the navigationMetaData to trigger re-rendering of the UI
          if (bindedMetaData.navigation_metadata) {
            setNavigationMetaData(bindedMetaData.navigation_metadata!);
          }

          if (bindedMetaData.session_expires_at) {
            setSessionExpiry(bindedMetaData.session_expires_at!);
          }

          if (isFileUpload) {
            // re-initialize wizard
            setInstanceKey();
          }
        }
      })
      .catch((err) => {
        // render the error UI with error message
        setDisplayErrorAbstraction(parseErrorAbstraction(err));
      });
  };

  // callback function on PIDL rendering success
  const callbackOnSuccess = (data: any) => {
    // dismiss error message banner when successful on next & save click
    setServiceErrorMessage("");

    // edge case for handling otherdocumentation & questionnaire fileupload success event:
    // when fileupload succeede, only refresh the metadata
    if (
      (data.pageId === Constants.otherDocumentationPageId ||
        data.pageId === Constants.questionnairePageId) &&
      !data.isNext
    ) {
      refreshMetadata(true);
    } else {
      // determine if its the last step, and do the following:
      // 1. refresh metadata and re-render the next section if it's not the last section
      // 2. redirect user to home page if it's the last section

      if (canGoToNextStep(wizardProps.steps)) {
        // disable footer after next is clicked
        toggleLoadingFooter(true);

        const ind: number = findCurrentIndex(wizardProps.steps);
        const loadingTextOnNext: string =
          navigationMetaData[ind].next_message || "";

        // disable left navigation and rendering intermediate loading screen for getPidlDocument
        setWizardProps({
          ...wizardProps,
          isLoading: true,
          loadingPaneProps: {
            primaryLoadingText: loadingTextOnNext,
          },
        });

        refreshMetadata(false);
      } else {
        // clear the existing cookies
        CookieUtil.resetCookie();

        // redirect user to tenant on button click.
        window.location.href = localStorage.getItem(
          Constants.returnUrlCookieName
        )!;
      }
    }
  };

  // callback function on PIDL rendering failure & axios request failure
  const callbackOnFailure = (data: any) => {
    const errorMessage = !data.isAxios
      ? data.error?.innererror?.error?.message
      : data?.response?.data?.error?.message;
    const status = !data.isAxios ? data.error?.status : data.response?.status;
    if (status === 400) {
      // display a pink error message banner
      setServiceErrorMessage(errorMessage);

      // re-initialize wizard
      setInstanceKey();
    } else if (status === 401 || status === 500 || status === 503) {
      // display terminal error screen
      let res: TaxProfileRequestError = {} as TaxProfileRequestError;
      res.message = errorMessage;
      setDisplayErrorAbstraction(res);
    } else {
      // display a pink error message banner
      if (!MiscUtil.isNullOrUndefinedOrEmptyString(errorMessage)) {
        setServiceErrorMessage(errorMessage);
      }
    }
  };

  // Handler for addressing pre-defined events from PIDL SDK
  const onPidlEvent: pidl.PidlEventHandler = (
    eventName: string,
    parameters: any
  ): void => {
    // TODO: manually added title attribute to fix the iframe accessibility bug https://microsoftit.visualstudio.com/OneITVSO/_workitems/edit/11092610
    //       need to remove this code once the PIDL team fixed iframe issue on their end.
    if (
      eventName === EventName.PageRefreshed &&
      parameters.pageId === Constants.taxFormPageId
    ) {
      const iframe = document.getElementById("iframe_cti_url");
      if (iframe) {
        iframe.setAttribute("title", "iframe_cti_title");
      }
    }

    // disable footer while file uploading in otherdocumentation & questionnaire
    if (
      (eventName === EventName.FileUploading ||
        eventName === EventName.InputSubmitting) &&
      (parameters.pageId === Constants.otherDocumentationPageId ||
        parameters.pageId === Constants.questionnairePageId)
    ) {
      toggleLoadingFooter(true);
      setWizardProps({
        ...wizardProps,
        isLoading: false,
      });
    }

    // start intermediate loading on next button click when all form vaidation passes
    if (eventName === EventName.InputSubmitting) {
      // disable footer after next is clicked
      toggleLoadingFooter(true);

      // disable intermediate loading for otherdocumentation & questionnaire
      if (
        parameters.pageId !== Constants.otherDocumentationPageId &&
        parameters.pageId !== Constants.questionnairePageId
      ) {
        const ind: number = findCurrentIndex(wizardProps.steps);
        const loadingTextOnSave: string =
          navigationMetaData[ind].save_message || "";

        // disable left navigation and rendering intermediate loading scree for save
        setWizardProps({
          ...wizardProps,
          isLoading: true,
          loadingPaneProps: {
            primaryLoadingText: loadingTextOnSave,
          },
        });
      }
    }

    // stop intermediate loading on back button click when thte pidl page is rendered or internal server error occurs
    if (eventName === EventName.PageRendered || eventName === EventName.Error) {
      toggleLoadingFooter(false);
      setWizardProps({
        ...wizardProps,
        isLoading: false,
      });
    }

    // show error banner when non-terminal error occur while uploading files in otherdocumentation & questionnaire.
    if (
      eventName === EventName.Error &&
      (parameters.pageId === Constants.otherDocumentationPageId ||
        parameters.pageId === Constants.questionnairePageId)
    ) {
      const isFileUpload: boolean =
        parameters.error.url?.split("/").pop() === "uploaduserdocument";
      if (isFileUpload) {
        // handle uploaduserdocument failure
        callbackOnFailure(parameters);
      }
    }
  };

  const _getContentTitleElement = (stepStr: string): JSX.Element => {
    return <span>{stepStr}</span>;
  };

  /**
   * render PIDL based on the section provided
   * @param section - The name of the section
   * @returns the PIDL SDK compoent
   */
  const resourceView = (section: string): JSX.Element => {
    return !MiscUtil.isNullOrUndefinedorEmptyObject(pidlConfigParams) ? (
      <ResourceView
        // use ref callback to dynamically update the ref
        ref={(newResourceViewRef) => {
          if (newResourceViewRef) {
            resourceViewRef = newResourceViewRef;
          }
        }}
        callbackOnSuccess={callbackOnSuccess}
        callbackOnFailure={callbackOnFailure}
        onPidlEvent={onPidlEvent}
        pidlConfigParams={{
          ...pidlConfigParams,
          section: section,
        }}
        leftNavigation={navigationMetaData}
        // trigger DOM re-rendering on PIDL SDK when resourceView is called.
        // key={JSON.stringify(key)}
      />
    ) : (
      <div>Loading PIDL SDK...</div>
    );
  };

  const inBetweenFooter = (isLoading: boolean) => {
    return (
      <WizardActionBar
        isLoading={isLoading}
        backAction={_goToPrevStepAction()}
        mainAction={_goToNextStepAction()}
        cancelAction={_goToCancelStepAction()}
        currentStep={{} as IWizardStepProps}
      />
    );
  };
  const lastFooter = (isLoading: boolean, disableBack: boolean = false) => {
    return disableBack ? (
      <WizardActionBar
        isLoading={isLoading}
        mainAction={_goToCompleteStepAction()}
        cancelAction={_goToCancelStepAction()}
        currentStep={{} as IWizardStepProps}
      />
    ) : (
      <WizardActionBar
        isLoading={isLoading}
        backAction={_goToPrevStepAction()}
        mainAction={_goToCompleteStepAction()}
        cancelAction={_goToCancelStepAction()}
        currentStep={{} as IWizardStepProps}
      />
    );
  };

  // dynamically construct IWizardStepProps steps based on the navigationMetaData
  const _getAllSteps = (_isLoadingParam?: boolean): IWizardStepProps[] => {
    // TODO: manually setup for steps, needs to figure out a better way to declare it.
    if (_isLoadingParam) {
      return [
        {
          id: "0",
          label: "Core details",
          state: SubwayNavNodeState.Current,
          footerElement: _getRegularFirstFooter(false),
          announcedProps: { message: "Core details" },
          wizardContent: {
            contentTitleElementAs: "h3",
            contentTitleElement: _getContentTitleElement("Core details"),
            content: <div>hello</div>,
          },
        },
      ];
    }

    const steps: IWizardStepProps[] = navigationMetaData.map((node, i) => {
      return {
        id: node.id.toString(),
        label: node.label,
        state: node.is_active
          ? SubwayNavNodeState.Current
          : SubwayNavNodeState.NotStarted,
        footerElement: getFooterById(node.id),
        announcedProps: { message: node.announced_message },
        wizardContent: {
          contentTitleElementAs: "h3",
          contentTitleElement: _getContentTitleElement(node.label),
          content: resourceView(node.pidl_metadata_phase_id.toLowerCase()),
        },
        pidl_metadata_phase_id: node.pidl_metadata_phase_id,
      };
    });

    return steps;
  };

  const [wizardProps, setWizardProps] = useState<IWizardProps>({
    steps: _getAllSteps(true),
    isLoading: true,
  });

  // trigger UI re-rendering on navigationMetaData and localizationData update
  useEffect(() => {
    if (
      !MiscUtil.isNullOrUndefinedOrEmptyArray(navigationMetaData) &&
      !MiscUtil.isNullOrUndefinedorEmptyObject(uiElementLabels)
    ) {
      const steps = _getAllSteps();
      wizardProps.steps = steps;
      setWizardProps({
        ...wizardProps,
        isLoading: false,
      });

      // enable footer after the UI re-rendered
      toggleLoadingFooter(false);
    }
  }, [navigationMetaData, uiElementLabels]);

  const MessageBanner = (message: string, messageType: MessageType) => {
    const handleOnDismiss = () => {
      if (messageType === MessageType.ServiceError) {
        setServiceErrorMessage("");
      } else if (messageType === MessageType.ValidationWarning) {
        setValidationWarningMessage("");
      } else {
        setBrowserWarningMessage("");
      }
    };

    const parsedMessage = message.split("|");

    // TODO: need to either make the content scrollable or collapsible when the bullet list grows too much
    //       link for reference: https://developer.microsoft.com/en-us/fluentui#/controls/web/messagebar
    return (
      <MessageBar
        messageBarType={
          messageType === MessageType.ServiceError
            ? MessageBarType.error
            : MessageBarType.warning
        }
        onDismiss={handleOnDismiss}
        dismissButtonAriaLabel="Close"
      >
        {/* only render the bullet list if there are '|' seperated values' */}
        {parsedMessage.length === 1 ? (
          <b>{message}</b>
        ) : (
          // remove unwanted margin of unordered list
          <ul style={{ margin: "0px" }}>
            {parsedMessage.map((x) => (
              <li>
                <b>{x}</b>
              </li>
            ))}
          </ul>
        )}
      </MessageBar>
    );
  };

  return (
    <>
      <div id="content">
        <CoherenceHeader
          headerLabel="Tax profile"
          appNameSettings={{
            ariaLabel: "Tax profile",
            label: "Tax profile",
          }}
          farRightSettings={{
            profileSettings: getProfilePanelSettings(
              authenticationState === AuthenticationStateEnum.Authenticated
                ? CookieUtil.getCookie(Constants.userNameCookieName)!
                : "Unknown User"
            ),
          }}
        />
        {!MiscUtil.isNullOrUndefinedOrEmptyString(serviceErrorMessage) &&
          MessageBanner(serviceErrorMessage, MessageType.ServiceError)}
        {!MiscUtil.isNullOrUndefinedOrEmptyString(validationWarningMessage) &&
          MessageBanner(
            validationWarningMessage,
            MessageType.ValidationWarning
          )}
        {!MiscUtil.isNullOrUndefinedOrEmptyString(browserWarningMessage) &&
          MessageBanner(browserWarningMessage, MessageType.BrowserWarning)}
        {!MiscUtil.isNullOrUndefinedorEmptyObject(displayErrorAbstraction) ? (
          <ErrorAbstractionPage requestError={displayErrorAbstraction} />
        ) : (
          <>
            {authenticationState === AuthenticationStateEnum.Authenticated &&
              (!MiscUtil.isNullOrUndefinedOrEmptyArray(navigationMetaData) &&
              !MiscUtil.isNullOrUndefinedorEmptyObject(uiElementLabels) ? (
                <CoherenceWizard
                  type={WizardType.FULL_PAGE}
                  wizardProps={{
                    ...wizardProps,
                    ...commonWizardProps,
                  }}
                  key={instanceKey}
                />
              ) : (
                <div style={loadingStyles}>
                  <CoherenceLoading primaryText={t("loading") + "..."} />
                </div>
              ))}
            {authenticationState === AuthenticationStateEnum.Authenticating && (
              // loading screen for authorization only on inital load
              <div style={loadingStyles}>
                <CoherenceLoading primaryText={t("authorizing") + "..."} />
              </div>
            )}
          </>
        )}
        {!MiscUtil.isNullOrUndefinedorEmptyObject(uiElementLabels) && (
          <Dialog
            hidden={hideCancelDialog}
            onDismiss={toggleHideCancelDialog}
            dialogContentProps={cancelDialogContentProps(
              uiElementLabels.cancel_title,
              uiElementLabels.cancel_message
            )}
            modalProps={{
              isBlocking: true,
              styles: dialogStyles,
            }}
          >
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  // clear the existing cookies
                  CookieUtil.resetCookie();

                  // redirect user to tenant on button click.
                  window.location.href = localStorage.getItem(
                    Constants.returnUrlCookieName
                  )!;
                }}
                text={uiElementLabels.cancel_confirm_text}
              />
              <DefaultButton
                onClick={toggleHideCancelDialog}
                text={uiElementLabels.cancel_button_text.toLowerCase()}
              />
            </DialogFooter>
          </Dialog>
        )}
        <Dialog
          hidden={hideTimeOutDialog}
          onDismiss={toggleHideTimeOutDialog}
          dialogContentProps={timeoutDialogContentProps(
            uiElementLabels.timeout_error_title,
            uiElementLabels.timeout_error_message
          )}
          modalProps={{
            isBlocking: true,
            styles: dialogStyles,
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                // redirect user to tenant on button click.
                window.location.href = localStorage.getItem(
                  Constants.returnUrlCookieName
                )!;
              }}
              text={uiElementLabels.timeout_return_button_text}
            />
          </DialogFooter>
        </Dialog>
      </div>
      <Footer uiElementLabels={uiElementLabels} />
    </>
  );
};
