import * as Constants from "../constant/constants";

export class CookieUtil {
  /**
   * set cookie with params
   * @param name - The name of the cookie
   * @param val - The value of the cookie
   * @param time - The time the cookie will last in minutes
   * @returns None
   */
  public static setCookie(name: string, val: string, time: number) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + time * 60 * 1000);

    // Set it
    document.cookie =
      name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  /**
   * retrieve the value of a cookie
   * @param name - The name of the cookie
   * @returns value of the cookie
   */
  public static getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop()?.split(";").shift();
    }
  }

  /**
   * delete a cookie with its name
   * @param name - The name of the cookie
   * @returns None
   */
  public static deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }

  /**
   * remove all tax profile related cookies
   * @returns None
   */
  public static resetCookie() {
    const cookies = [
      Constants.correlationIdCookieName,
      Constants.cultureNameCookieName,
      Constants.expiryCookieName,
      Constants.payeeIdCookieName,
      Constants.sessionIdCookieName,
      Constants.userNameCookieName,
      Constants.userKeyCookieName,
    ];

    cookies.forEach((cookie) => {
      this.deleteCookie(cookie);
    });
  }

  /**
   * refresh cookies with a new timeout
   * @returns None
   */
  public static refreshCookie(time: number) {
    const cookies = [
      Constants.correlationIdCookieName,
      Constants.cultureNameCookieName,
      Constants.expiryCookieName,
      Constants.payeeIdCookieName,
      Constants.sessionIdCookieName,
      Constants.userNameCookieName,
      Constants.userKeyCookieName,
    ];

    cookies.forEach((cookie) => {
      this.setCookie(cookie, this.getCookie(cookie)!, time);
    });
  }
}
