export enum AuthenticationState {
  Authenticating,
  Authenticated,
  Unauthenticated,
}

export enum TenantType {
  PartnerCenter = "Partner Center",
  SupplierWeb = "SupplierWeb",
  PaymentCentral = "Payment Central",
}

export enum ProfileStatue {
  // In Progress
  INP = "INP",
  // Pending Microsoft Action
  PMA = "PMA",
  // Pending Payee Action
  PPA = "PPA",
  // Completed
  CPD = "CPD",
  // Ready for submission
  RFS = "RFS",
  // Canceled
  CAN = "CAN",
}

export enum AccountType {
  Individual = "individual",
  Organization = "organization",
}

export enum SectionName {
  CoreDetails = "coredetails",
  BusinessProfile = "businessprofile",
  TaxResidenceCountry = "taxresidencecountry",
  AdditionalTaxIdentifiers = "additionaltaxidentifiers",
  OtherDocumentation = "otherdocumentation",
  Questionnaire = "questionnaire",
  TaxForm = "taxform",
}

export enum EventName {
  PageRefreshed = "pageRefreshed",
  FileUploading = "fileUploading",
  InputSubmitting = "inputSubmitting",
  PageRendered = "pageRendered",
  Error = "error",
}

export interface TaxProfileRequestError {
  code?: string;
  title?: string;
  message: string;
}

export interface LeftNavigation {
  id: number;
  label: string;
  is_active: boolean;
  announced_message: string;
  pidl_metadata_phase_id: SectionName;
  save_message: string;
  back_message: string;
  next_message: string;
  description: string | null;
  country_list_display: string | null;
  add_button_text: string | null;
  add_tax_identifier_title: string | null;
  pidl_laoding_text: string | null;
  country_label: string | null;
  identifier_label: string | null;
  tax_identifier_label: string | null;
  save_button_text: string | null;
  cancel_button_text: string | null;
  country_dropdown_title: string | null;
  country_dropdown_placeholder: string | null;
  question_dropdown_title: string | null;
  question_dropdown_placeholder: string | null;
  save_spinner_text: string | null;
}

export interface CoreDetails {
  residence_country: string;
  account_type: AccountType;
}

export interface BusinessProfile {
  first_name: string;
  last_name: string;
  "d&a": string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

export interface TaxResidenceCountry {
  country: string;
  tax_id_type: string;
  inn: number;
  kpp: number;
  tax_id: string;
}

export interface PayfromCountryDetailsType {
  Key: string;
  Value: string;
}

interface QuestionOptionType {
  QuestionCode: string;
  QuestionLabel: string;
}

export interface QuestionnaireDetailsType {
  Country: string;
  WHTComplianceQuestions: QuestionOptionType[];
}

export interface OtherDocumentation {
  // TODO: not sure what data shoulud be placed in here so TBD
}

interface FooterElement {
  text: string;
  link: string | null;
}

export interface UiElementLabels {
  // app level localization
  back_button_text: string;
  next_button_text: string;
  complete_button_text: string;
  save_button_text: string;
  cancel_button_text: string;
  footer: FooterElement[];
  timeout_title: string;
  timeout_message: string;
  timeout_return_button_text: string;
  timeout_error_title: string;
  timeout_error_message: string;
  cancel_title: string;
  cancel_message: string;
  cancel_confirm_text: string;
  cancel_reject_text: string;
}

// the following two localized strings represent the default errros
// shown to the user in situation when backend is not contacted
export interface PreLoadingLocalization {
  loading: string;
  authorizing: string;
  default_error_title: string;
  default_error_message: string;
}

export interface TaxProfileMetaData {
  browser_warning: string | null;
  validation_message: string | null;
  user_name: string;
  email: string;
  tenant_id: string;
  return_url: string;
  session_timeout: number;
  pidl_prefill_data: {
    core_details?: CoreDetails;
    business_profile?: BusinessProfile;
    tax_residence_country?: TaxResidenceCountry;
    other_documentation?: OtherDocumentation;
  };
  culture_name: string;
  auth_token: string;
  profile_status: ProfileStatue;
  sap_indicator: string;
  tax_residency_country: string;
  pay_from_countries: string[];
  payfrom_country_details: PayfromCountryDetailsType[];
  account_type: AccountType;
  navigation_metadata: LeftNavigation[];
  country_code_vat_types: any;
  payee_additional_info: any;
  country_income_codes: any;
  ui_element_labels: UiElementLabels;
  uploadDocument_help_message1: string;
  uploadDocument_help_message2: string;
  uploadDocument_help_message3: string;
  questionnaire_help_message1: string;
  questionnaire_help_message2: string;
  questionnaire_help_message3: string;
  otherdocumentation_selectedCountryCode: string;
  questionnaire_selectedquestioncode: string;
  session_expires_at: string;
  bank_countries: string[] | null;
  questionnaire_details: QuestionnaireDetailsType[] | null;
}

export interface PidlConfigParams {
  section?: string;
  profile_status: string;
  sap_indicator: string;
  tax_residency_country: string;
  pay_from_countries: string[];
  payfrom_country_details: PayfromCountryDetailsType[];
  account_type: string;
  tenant_id: string;
  culture_name: string;
  pidl_prefill_data: any;
  country_code_vat_types: any;
  payee_additional_info: any;
  country_income_codes: any;
  uploadDocument_help_message1: string;
  uploadDocument_help_message2: string;
  uploadDocument_help_message3: string;
  questionnaire_help_message1: string;
  questionnaire_help_message2: string;
  questionnaire_help_message3: string;
  otherdocumentation_selectedCountryCode: string;
  questionnaire_selectedquestioncode: string;
  bank_countries: string[] | null;
  questionnaire_details: QuestionnaireDetailsType[] | null;
}

export interface AdditionalHeadersType {
  showcti?: string;
  incometypecodes?: string;
  authToken: string;
  questioncode?: string;
}
