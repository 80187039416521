import React, { forwardRef } from "react";
import { Environment, pidl, IElementFactory } from "@cspayments/pidl-react";
import {
  PidlConfigParams,
  LeftNavigation,
} from "../schema/taxProfileMetaData.types";
import { AdditionalTaxIdentifier } from "./additionalTaxIdentifier";
import { OtherDocumentation } from "./otherDocumentation";
import { SectionName } from "../schema/taxProfileMetaData.types";
import { PidlGenerator } from "./pidlGenerator";
import { PrefilldataHelper } from "../utils/prefilldataHelper";
import { Quetionnaire } from "./questionnaire";

export declare type WindowSize =
  | "fullScreen"
  | "windowsStoreModal"
  | "amcDialog";
export interface IResourceViewerState {
  returnOptions: pidl.IDisplayControlReturnOptions;
  userContext: pidl.IDisplayControlUserContext;
  displayOptions: Partial<pidl.IDisplayControlDisplayOptions>;
  telemetryOptions?: pidl.IDisplayControlTelemetryOptions;
  env: Environment;
  pidlDocInfo: pidl.IPidlDocInfo | pidl.ISelectResourcePidlDocInfo;
  prefillData?: any;
  apiOptions?: pidl.IApiOptions;
  elementFactory?: IElementFactory;
  resourceKey: any;
  callbacks?: pidl.ICallbacks;
  windowSize: WindowSize;
  ref?: React.RefObject<any>;
}

export interface ResourceViewRefType {
  goNext(): void;
  goBack(): void;
}

type PropsType = {
  callbackOnSuccess: (data?: any) => void;
  callbackOnFailure: (data?: any) => void;
  onPidlEvent: (eventName: string, parameters: any) => void;
  pidlConfigParams: PidlConfigParams;
  leftNavigation: LeftNavigation[];
};

export const ResourceView = forwardRef<ResourceViewRefType, PropsType>(
  (props, parentRef) => {
    const { section, pidl_prefill_data } = props.pidlConfigParams;

    const navigationDataMapper = (
      data: LeftNavigation[],
      section: string
    ): LeftNavigation => {
      return data.find(
        (node) => node.pidl_metadata_phase_id.toLowerCase() === section
      )!;
    };

    return (
      <>
        {(() => {
          if (section === SectionName.AdditionalTaxIdentifiers) {
            return (
              // render costom UI for non-resident vat section
              <AdditionalTaxIdentifier
                ref={parentRef}
                prefillData={{
                  ...PrefilldataHelper.prefillDataMapper(
                    pidl_prefill_data,
                    section
                  ),
                }}
                callbackOnSuccess={props.callbackOnSuccess}
                callbackOnFailure={props.callbackOnFailure}
                onPidlEvent={props.onPidlEvent}
                pidlConfigParams={props.pidlConfigParams}
                leftNavigation={navigationDataMapper(
                  props.leftNavigation,
                  section
                )}
              />
            );
          } else if (section === SectionName.OtherDocumentation) {
            return (
              // render costom UI for other documentation section
              <OtherDocumentation
                ref={parentRef}
                prefillData={{
                  ...PrefilldataHelper.prefillDataMapper(
                    pidl_prefill_data,
                    section
                  ),
                }}
                callbackOnSuccess={props.callbackOnSuccess}
                callbackOnFailure={props.callbackOnFailure}
                onPidlEvent={props.onPidlEvent}
                pidlConfigParams={props.pidlConfigParams}
                leftNavigation={navigationDataMapper(
                  props.leftNavigation,
                  section
                )}
              />
            );
          } else if (section === SectionName.Questionnaire) {
            return (
              // render costom UI for quetionnaire section
              <Quetionnaire
                ref={parentRef}
                prefillData={{
                  ...PrefilldataHelper.prefillDataMapper(
                    pidl_prefill_data,
                    section
                  ),
                }}
                callbackOnSuccess={props.callbackOnSuccess}
                callbackOnFailure={props.callbackOnFailure}
                onPidlEvent={props.onPidlEvent}
                pidlConfigParams={props.pidlConfigParams}
                leftNavigation={navigationDataMapper(
                  props.leftNavigation,
                  section
                )}
              />
            );
          } else {
            return (
              <PidlGenerator
                ref={parentRef}
                callbackOnSuccess={props.callbackOnSuccess}
                callbackOnFailure={props.callbackOnFailure}
                onPidlEvent={props.onPidlEvent}
                pidlConfigParams={props.pidlConfigParams}
              />
            );
          }
        })()}
      </>
    );
  }
);
