import React, { forwardRef, useState, useEffect } from "react";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react/lib/Dropdown";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Text } from "@fluentui/react";
import {
  PidlConfigParams,
  LeftNavigation,
  QuestionnaireDetailsType,
  EventName,
} from "../schema/taxProfileMetaData.types";
import { pidl } from "@cspayments/pidl-react";
import { PidlGenerator, PidlGeneratorRefType } from "./pidlGenerator";
import { MiscUtil } from "../utils/miscUtil";
import { CookieUtil } from "../utils/cookieUtil";
import * as Constants from "../constant/constants";
import { useTranslation } from "react-i18next";

interface OtherDocumentationRefType {
  goNext(): void;
  goBack(): void;
}

type OtherDocumentationPropsType = {
  prefillData: any;
  callbackOnSuccess: (data?: any) => void;
  callbackOnFailure: (data?: any) => void;
  onPidlEvent: (eventName: string, parameters: any) => void;
  pidlConfigParams: PidlConfigParams;
  leftNavigation: LeftNavigation;
};

// Styling definitions
const parentStackTokens: IStackTokens = {
  childrenGap: 10,
  maxWidth: 1000,
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
  root: { height: 100 },
};
const saveStack: IStackTokens = {
  maxWidth: 80,
  childrenGap: 10,
  padding: "30px 0px 0px 0px",
};
const pidlStack: IStackTokens = { padding: "30px 0px 0px 0px" };

/**
 * helper function to construct an array of object that can be used for rendering dropdown options
 * The dropdown contains all the questionnaire in the questionnaireList.
 * @param questionnaireDetails - An array of objects contains questionnaire code and label with its country
 * @returns a list of questionnaire key & text that can be used for rendering dropdown options
 */
const questionnaireOptionsConstructor = (
  questionnaireDetails: QuestionnaireDetailsType[] | null
): IDropdownOption[] => {
  if (MiscUtil.isNullOrUndefinedOrEmptyArray(questionnaireDetails)) {
    return [];
  }

  let res: IDropdownOption[] = [];
  questionnaireDetails!.forEach((questionnaireDetail, i) => {
    // Add country title to the dropdown list
    const [countryKey, countryvalue] = questionnaireDetail.Country.split("|");
    res.push({
      key: countryKey,
      text: countryvalue,
      itemType: DropdownMenuItemType.Header,
    });

    // Add questionnaire options after the title
    questionnaireDetail.WHTComplianceQuestions.forEach((questionnaire, j) => {
      res.push({
        key: questionnaire.QuestionCode,
        text: questionnaire.QuestionLabel,
      });
    });
  });

  return res;
};

export const Quetionnaire = forwardRef<
  OtherDocumentationRefType,
  OtherDocumentationPropsType
>((props, parentRef) => {
  let saveButtonRef: PidlGeneratorRefType;

  const { t } = useTranslation();
  const { prefillData, pidlConfigParams } = props;
  const [questionnaireList, setQuestionnaireList] = useState<IDropdownOption[]>(
    questionnaireOptionsConstructor(pidlConfigParams.questionnaire_details)
  );
  const [selectedQuestionnaireCode, setSelectedQuestionnaireCode] =
    useState<string>(pidlConfigParams.questionnaire_selectedquestioncode);
  // state controls the uploading spinner
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [pidlLoaded, setPidlLoaded] = useState<boolean>(false);

  // set the default questionnaire code to the first one in the dropdown
  // if questionnaire_selectedquestioncode does not exist.
  const handleQuestionnaire = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ): void => {
    setSelectedQuestionnaireCode(option?.key.toString() || "");
  };

  // custom save button event handler that binds to the submit pidlAction in pidl.json
  const handleUpload = () => {
    saveButtonRef?.goNext();
  };

  // callback function on PIDL rendering success
  const callbackOnSuccess = (data: any) => {
    setIsUploading(false);
    props.callbackOnSuccess({
      ...data,
      isNext: false,
    });
  };

  // callback function on PIDL rendering failure
  const callbackOnFailure = (data: any) => {
    setIsUploading(false);
    props.callbackOnFailure(data);
  };

  // Handler for addressing pre-defined events from PIDL SDK
  const onPidlEvent: pidl.PidlEventHandler = (
    eventName: string,
    parameters: any
  ): void => {
    if (eventName === "pageRendered") {
      setPidlLoaded(true);
    }
    // disable save button & dropdown while file uploading & InputSubmitting
    if (
      eventName === EventName.FileUploading ||
      eventName === EventName.InputSubmitting
    ) {
      setIsUploading(true);
    }
    props.onPidlEvent(eventName, parameters);
  };

  // set the default questionnaire to the first one in the dropdown
  // if questionnaire_selectedquestioncode does not exist.
  useEffect(() => {
    if (
      !MiscUtil.isNullOrUndefinedOrEmptyArray(questionnaireList) &&
      MiscUtil.isNullOrUndefinedOrEmptyString(selectedQuestionnaireCode)
    ) {
      // filter out the dropdown header
      const defaultQuestionnaireCode = questionnaireList.find(
        (questionnaire) => !("itemType" in questionnaire)
      );
      setSelectedQuestionnaireCode(
        defaultQuestionnaireCode?.key.toString() || ""
      );
    }
  }, [questionnaireList]);

  return (
    <Stack tokens={parentStackTokens}>
      {questionnaireList.length === 0 ? (
        <div>No Questionnaire Required</div>
      ) : (
        <>
          {!MiscUtil.isNullOrUndefinedOrEmptyString(
            selectedQuestionnaireCode
          ) && (
            <>
              <Dropdown
                label={props.leftNavigation?.question_dropdown_title!}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleQuestionnaire}
                placeholder={
                  props.leftNavigation?.question_dropdown_placeholder!
                }
                options={questionnaireList}
                defaultSelectedKey={selectedQuestionnaireCode}
                styles={dropdownStyles}
                disabled={isUploading}
              />
              <Text>{props.pidlConfigParams.questionnaire_help_message1}</Text>
              <Text>{props.pidlConfigParams.questionnaire_help_message2}</Text>
              <Text>{props.pidlConfigParams.questionnaire_help_message3}</Text>
              <Stack tokens={pidlStack}>
                {!pidlLoaded && <Spinner label={t("loading") + "..."} />}
                <PidlGenerator
                  ref={(pidlGeneratorRef) => {
                    if (pidlGeneratorRef) {
                      saveButtonRef = pidlGeneratorRef;
                    }
                  }}
                  callbackOnSuccess={callbackOnSuccess}
                  callbackOnFailure={callbackOnFailure}
                  onPidlEvent={onPidlEvent}
                  pidlConfigParams={{
                    ...props.pidlConfigParams,
                    questionnaire_selectedquestioncode:
                      selectedQuestionnaireCode,
                  }}
                  additionalHeaders={{
                    authToken:
                      CookieUtil.getCookie(Constants.sessionIdCookieName)! +
                      "|" +
                      CookieUtil.getCookie(Constants.userKeyCookieName)!,
                    questioncode: selectedQuestionnaireCode,
                  }}
                  // update PIDL rendering based on selected questionnaire code change.
                  key={selectedQuestionnaireCode}
                />
              </Stack>
              <Stack horizontal tokens={saveStack}>
                <PrimaryButton
                  text={props.leftNavigation.save_button_text!}
                  onClick={handleUpload}
                  disabled={isUploading}
                />
                {isUploading && (
                  <Spinner
                    labelPosition="right"
                    label={props.leftNavigation.save_spinner_text!}
                  />
                )}
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
});
